import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch, FiUpload } from "react-icons/fi";
import ResponsiveGrid from "./showCategory/showCategory";
import "./VirtualStore.css";
import { IoIosAdd, IoIosAddCircleOutline, IoMdAddCircle } from "react-icons/io";
import Button from "@mui/material/Button";
import { FaEdit } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { RiDeleteBin5Fill } from "react-icons/ri";
import InputLabel from "@mui/material/InputLabel";
import { BiReset } from "react-icons/bi";
import Swal from "sweetalert2";
import { FaPenClip } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
// import GlobalURL from "../BaseUrl";
import GlobalURL from "../BaseUrl";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { LiaAngleRightSolid } from "react-icons/lia";
import Modal from "react-bootstrap/Modal";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { FaImage, FaPenAlt } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { useRef } from "react";
import DataGridDemo from "./storeTable/storeTable";
import ClipLoader from "react-spinners/ClipLoader";

const VIrtualStore = () => {
  const language = useSelector((state) => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const useRefCat = useRef(null);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [offerName, setOfferName] = useState("");
  const [offers, setOffers] = useState([]);
  const [offers2, setOffers2] = useState([]);
  const [custOffer, setCustOffer] = useState();
  const [selectedOffer, setSelectedOffer] = useState("");
  const [selectedOfferId, setSelectedOfferId] = useState("");
  const [selectedOption, setSelectedOption] = useState("Cuadrícula"); // option selected
  const [selecedProDuctId, setSelecedProDuctId] = useState("");
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const [cust2offer, setCust2offer] = useState("");
  const [start2Date, setStart2Date] = useState("");
  const [end2Date, setEnd2Date] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loadPro, setLoadPro] = useState(true);
  const [loadCat, setLoadCat] = useState(true);
  const [creload, setCreload] = useState(false);
  const [proOfLoad, setProOfLoad] = useState(false);
  const [remOffAll, setRemOffAll] = useState(false);
  const [applyOffAll, setApplyOffAll] = useState(false);
  const [deletingOffer, setDeletingOffer] = useState(false);
  const [deletingSOffer, setDeletingSOffer] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [deletingPertiOffer, setDeletingPertiOffer] = useState(false);

  const [showModalEditCat, setShowModalEditCat] = useState(false);

  const [newCatImg, setNewCatImg] = useState("");
  const [prevnewCatImg, setPrevnewCatImg] = useState("");
  const [newcatName, setNewcatName] = useState("");
  const [newUnit, setNewunit] = useState("");
  const [upCat, setUpCat] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [isRunOff, setIsRunOff] = useState(false);
  const [banner, setBanner] = useState([]);
  const [category, setCategory] = useState([]);

  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [originalData2, setOriginalData2] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [catId, setCatId] = useState("");

  const handleChangeCatInput = (e) => {
    console.log("executed: ");
    useRefCat.current.click();
  };
  const handleSearch = (e) => {
    if (selectedOption !== "Cuadrícula") {
      const dataTemp2 = originalData2;
      console.log("originalData:2 ", originalData2);
      setSearchTerm(e.target.value);
      console.log("Search Term: ", e.target.value);
      if (e.target.value === "") {
        setCategory(originalData2);
        return;
      }
      const result = dataTemp2.filter((item) =>
        item.category_name.toLowerCase().includes(e.target.value)
      );
      console.log("result: ", result);

      if (result.length === 0) {
        console.log("Empty Arry executed:  ");
        return;
      }
      setCategory(result);
      return;
    }

    const dataTemp = originalData;
    console.log("originalData: ", originalData);
    setSearchTerm(e.target.value);
    console.log("Search Term: ", e.target.value);
    if (e.target.value === "") {
      setBanner(originalData);
      return;
    }
    const result = dataTemp.filter((item) =>
      item.name.toLowerCase().includes(e.target.value)
    );
    console.log("result: ", result);

    if (result.length === 0) {
      console.log("Empty Arry executed:  ");
      return;
    }
    setBanner(result);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEditCat = () => {
    setShowModalEditCat(false);
  };
  const handleClickOpen2 = (item) => {
    setOpen2(true);
    setOpen3(false);
    setOpen(false);
    setSelecedProDuctId(item.product_id);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const setOffersui = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate);

    console.log(`first`, custOffer);
    console.log(
      "Dates Start: ",
      startDate,
      "End Date: ",
      endDate,
      "CurretnDate: ",
      currentDate
    );

    if (startDate >= endDate) {
      Swal.fire(
        t("La fecha de finalización debe ser mayor que la fecha de inicio")
      );
      return;
    }

    if (endDate === currentDate) {
      Swal.fire(
        t("La fecha de finalización debe ser mayor que la fecha de hoy.")
      );
      return;
    }
    if (startDate < currentDate) {
      Swal.fire(
        t("La fecha de inicio no puede ser menor que la fecha de hoy.")
      );
      return;
    }

    if (!custOffer || custOffer < 1 || custOffer > 100) {
      window.alert(t("Número sólo entre 1-100"));
      return;
    }

    // Check if the value is already present in the array

    if (startDate === "" || endDate === "") {
      window.alert(t("Incluir fechas también"));
      return;
    }
    if (offerName === "") {
      window.alert(t("Incluir nombre de oferta"));
      return;
    }

    // call API for add offer

    async function createOffer() {
      setCreload(true);
      const apiUrl = `${GlobalURL}/api/dev/app_moderator/createOffer`;
      const token = localStorage.getItem("token");
      const offerData = {
        name: offerName,
        discount_percentage: custOffer,
        start_date: startDate,
        end_date: endDate,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(offerData),
        });
        if (response.code === 401) {
          Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
          navigate("/login");
        }

        if (!response.ok) {
          console.error("Error: in adding offer", response.statusText);
          // Handle error as needed
          return;
        }
        if (response.ok) {
          const responseData = await response.json();
          console.log("Success:", responseData);
          setOpen3(false);
          setCreload(false);
          setOfferName("");
          setCustOffer("");
          setStartDate("");
          setEndDate("");
          Swal.fire({
            icon: "success",
            text: t("Hecho"),
          });
        }

        // Handle success response as needed
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire("Error del Servidor");
        // Handle error as needed
      }
    }

    createOffer();
  };
  // const setOffersui2 = () => {
  //     console.log(`first`, cust2offer);
  //     if (!cust2offer || cust2offer < 1) {
  //         window.alert('Number only between 1-100');
  //         return
  //     }
  //     if (!custOffer || custOffer < 1 || custOffer > 100) {
  //         window.alert('Number only between 1-100');
  //         return;
  //     }

  //     // Check if the value is already present in the array
  //     if (offers2.includes(custOffer)) {
  //         window.alert('Value already present');
  //         return;
  //     }
  //     if (start2Date === '' || end2Date === '') {
  //         window.alert('Include dates as well');
  //         return;
  //     }
  //     const offerData = {
  //         name: offerName,
  //         discount_percentage: custOffer,
  //         start_date: startDate,
  //         end_date: endDate
  //     };

  //     setOffers2([...offers2, cust2offer]);
  //     setSelectedOffer(cust2offer)
  //     setCustOffer('');

  // }
  const setSelectedDateForm = (item) => {
    setSelectedOffer(item.discount);
  };
  async function fetchOffers() {
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/store/getAllOfferProduct`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (!response.ok) {
        console.log("reponse not ok of offers");
        return;
      }
      if (response.ok) {
      }

      const data = await response.json();
      console.log("offers ", data.data);
      setOffers(data.data);

      // const discountDates = data.data
      //     .map((item) => {
      //         if (item.offer.discount_percentage === "") {
      //             setIsRunOff(true);
      //             return null; // or an appropriate placeholder value
      //         }

      //         return {
      //             discount: item.offer.discount_percentage,
      //             sDate: item.offer.start_date,
      //             eDate: item.offer.end_date,
      //             oname: item.offer.name,
      //         };
      //     })
      //     .filter(Boolean); // Remove null entries from the array

      // Now discountDates will contain only valid objects without empty discount_percentage

      // Create a Set to store unique values based on discount, sDate, and eDate
      // const uniqueDiscountDates = new Set(discountDates.map(JSON.stringify));
      // const uniqueDiscountDatesArray = Array.from(uniqueDiscountDates).map(
      //     JSON.parse
      // );
      // console.log("testing array", uniqueDiscountDatesArray);

      // Update the state with unique values
      // setOffers2(uniqueDiscountDatesArray);

      // Set the main offers state with the entire data
    } catch (error) {
      console.error("Error fetching Offers:", error.message);
    }
  }
  async function getAllPorduct() {
    setLoadPro(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/getAllProduct?page=${page}&limit=10`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.code === 401) {
        navigate("/login");
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        setLoadPro(false);
      }

      if (!response.ok) {
        console.error("Failed to fetch products:", response.statusText);
        setLoadPro(false);
        return;
      }

      const data = await response.json();
      console.log("Get all products:", data?.data);
      setBanner(data?.data);
      setOriginalData(data?.data);
      setLoadPro(false);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  }
  const getCategory = async () => {
    try {
      const resp = await fetch(
        `${GlobalURL}/api/dev/store/getAllProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        }
      );
      if (resp.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
      }
      const data = await resp.json();
      // const calcetinesCategory = data.find(category => category.category_name === "category_name");
      setCategory(data.data);
      setOriginalData2(data.data);
      setLoadCat(false);
    } catch (error) {
      console.log("error in Getting Category", error);
    }
  };
  const addOfferClicked = () => {
    setOpen3(true);
    setOpen2(false);
    setOpen(false);
  };
  const setSelectedDateForm2 = (item, index) => {
    setSelectedOffer(item.discount_percentage);
    setSelectedOfferId(item.ID); // use ofer id direct
    setSelectedIdx(index);
  };
  const applyToAllOffers = async () => {
    console.log("apply offer to all ID", selectedOfferId);
    setApplyOffAll(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/addOfferToAllProduct`,
        {
          // apply to all
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            offer_id: selectedOfferId,
          }),
        }
      );
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        console.error("Failed to apply offers", response.statusText);
        setApplyOffAll(false);
        Swal.fire(t("Error del Servidor"));
        return;
      }
      if (response.ok) {
        setApplyOffAll(false);
        setOpen(false);
        Swal.fire(t("Aplicado"));
        return;
      }

      const data = await response.json();
      console.log("Successfully applied");
    } catch (error) {
      setApplyOffAll(false);
      Swal.fire(t("Error del Servidor"));
    }
  };
  const applyToProductOffers = async () => {
    setProOfLoad(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/addOfferToProduct`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            offer_id: selectedOfferId,
            product_id: selecedProDuctId,
          }),
        }
      );
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (!response.ok) {
        console.error(
          "No se han podido aplicar las ofertas",
          response.statusText
        );
        setProOfLoad(false);
        Swal.fire(t("Error del Servidor"));
        return;
      }
      if (response.ok) {
        setProOfLoad(false);
        setOpen2(false);
        setOpen(false);
        setOpen3(false);
        Swal.fire(t("Hecho"));
      }
      console.log("Successfully applied");
    } catch (error) {
      setProOfLoad(false);
      Swal.fire(t("Error del Servidor"));
    }
  };
  const removeAllOffers = async () => {
    setRemOffAll(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/deleteAllOfferFromProducts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        console.error("Failed to apply offers", response.statusText);
        setRemOffAll(false);
        Swal.fire(t("Error del servidor"));
        return;
      }
      if (response.ok) {
        setRemOffAll(false);
        setOpen(false);
        Swal.fire(t("¡Remoto!"));
      }
      console.log("Successfully applied");
    } catch (error) {
      Swal.fire(t("no se pueden eliminar ofertas"));
    }
  };
  const removeProductOffers = async () => {
    setDeletingSOffer(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await fetch(
        `${GlobalURL}/api/dev/store/deleteAllOfferFromProduct`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            product_id: selecedProDuctId,
          }),
        }
      );
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        console.error("Failed to apply offers", response.statusText);
        setDeletingSOffer(false);
        return;
      }

      const data = await response.json();
      console.log("Successfully applied");
      if (response.ok) {
        Swal.fire("Hecho");
        setDeletingSOffer(false);
        setOpen2(false);
        setOpen(false);
        setOpen3(false);
      }
    } catch (error) {
      Swal.fire(t("no se pueden eliminar ofertas"));
      setDeletingSOffer(false);
    }
  };
  const fetchAllOffers = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/getAllOffer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
        return;
      }

      if (!response.ok) {
        console.error("Failed to Load offers", response.statusText);
        return;
      }

      const data = await response.json();

      console.log("all offers", data.data);
      if (data?.data?.length > 0) {
        setIsRunOff(true);
      }

      const newOffersData = data?.data?.map((item) => ({
        ID: item.offer_id,
        name: item.name,
        discount_percentage: item.discount_percentage,
        start_date: item.start_date,
        end_date: item.end_date,
        created: item.createdAt,
        updated: item.updatedAt,
      }));
      console.log("data Design", newOffersData);
      setOffers2(newOffersData);

      setSelectedOffer(custOffer);
      setCustOffer("");
      console.log("Successfully applied");
    } catch (error) {
      console.log("Server Error");
    }
  };
  const deleteOffer = async (item) => {
    setDeletingSOffer(true);
    const offerId22 = item.ID;
    console.log("OfferId to delete", offerId22);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("¿Está seguro?"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const token = localStorage.getItem("token");
            if (!token) {
              console.error("Token is missing.");
              return;
            }

            const response = await fetch(
              `${GlobalURL}/api/dev/app_moderator/deleteOffer`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": "69420",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  offer_id: offerId22,
                }),
              }
            );
            if (response.code === 401) {
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              navigate("/login");
              setDeletingSOffer(false);
            }

            if (!response.ok) {
              console.error("Failed to delete offers", response.statusText);
              Swal.fire(t("Error del Servidor"));
              setDeletingSOffer(false);
              return;
            }
            if (response.ok) {
              setDeletingSOffer(false);
              swalWithBootstrapButtons.fire({
                title: t("Eliminado"),
                text: "",
                icon: "success",
              });
            }

            const data = await response.json();
            console.log("todas las ofertas", data.data);

            const newOffersData = data.data.map((item) => ({
              ID: item.offer_id,
              name: item.name,
              discount_percentage: item.discount_percentage,
              start_date: item.start_date,
              end_date: item.end_date,
              created: item.createdAt,
              updated: item.updatedAt,
            }));
            console.log("Diseño de datos", newOffersData);
            setOffers2(newOffersData);

            setSelectedOffer(custOffer);
            setCustOffer("");
            console.log("Aplicado exitosamente");
          } catch (error) {
            Swal.fire(t("no se pueden eliminar ofertas"));
          }
        }
      });
  };
  const deletePerticularOfferFormProduct = async (offId, ProId) => {
    console.log(offId, "PRO ID  ||  ", ProId);
    setDeletingPertiOffer(true);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("¿Está seguro?"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const url = `${GlobalURL}/api/dev/app_moderator/deleteOfferFromProducts`;
          const adminToken = localStorage.getItem("token"); // Replace 'your_admin_token' with the actual admin token

          const data = {
            offer_id: offId,
            product_id: ProId,
          };

          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "69420",
                Authorization: `Bearer ${adminToken}`,
              },
              body: JSON.stringify(data),
            });

            if (!response.ok) {
              Swal.fire(t("Error del Servidor"));
              setOpen2(false);
              setDeletingPertiOffer(false);
            }
            if (response.ok) {
              const result = await response.json();
              console.log("API response:", result);
              setOpen2(false);
              setDeletingPertiOffer(false);
              swalWithBootstrapButtons.fire({
                title: t("¡Eliminado!"),
                text: "",
                icon: "success",
              });
            }
          } catch (error) {
            console.error("Error:", error.message);
            setDeletingPertiOffer(false);
            Swal.fire(t("Error al eliminar la oferta"));
          }
        }
      });
  };
  const deleteCategory = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("¿Está seguro?"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setShowLoadingModal(true);
          setDeletingOffer(true);
          try {
            const token = localStorage.getItem("token");
            if (!token) {
              console.error("Token is missing.");
              setShowLoadingModal(false);
              return;
            }

            const response = await fetch(
              `${GlobalURL}/api/dev/app_moderator/deleteCategory`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": "69420",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  category_id: id,
                }),
              }
            );

            if (response.code === 401) {
              setShowLoadingModal(false);
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              navigate("/login");
            }

            if (!response.ok) {
              setShowLoadingModal(false);
              console.error("Failed to delete category", response.statusText);
              Swal.fire(t("Error del Servidor"));
              setDeletingOffer(false);
              return;
            }
            if (response.ok) {
              setShowLoadingModal(false);
              swalWithBootstrapButtons.fire({
                title: t("Eliminado"),
                text: "",
                icon: "success",
              });
              setDeletingOffer(false);
            }

            const data = await response.json();
            setShowLoadingModal(false);
            console.log("todas las ofertas", data.data);
          } catch (error) {
            Swal.fire(t("no se pueden eliminar ofertas"));
            setShowLoadingModal(false);
            setDeletingOffer(false);
          }
        }
      });
  };
  const deleteProduct = async (id) => {
    console.log("id: of delete product", id);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("¿Está seguro?"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setShowLoadingModal(true);
          setDeletingOffer(true);
          try {
            const token = localStorage.getItem("token");
            if (!token) {
              console.error("Token is missing.");
              setShowLoadingModal(false);
              return;
            }

            const response = await fetch(
              `${GlobalURL}/api/dev/app_moderator/deleteProduct`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": "69420",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  product_id: id,
                }),
              }
            );

            if (response.code === 401) {
              setShowLoadingModal(false);
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              navigate("/login");
            }

            if (!response.ok) {
              setShowLoadingModal(false);
              console.error("Failed to delete category", response.statusText);
              Swal.fire(t("Error del Servidor"));
              return;
            }
            if (response.ok) {
              setShowLoadingModal(false);
              swalWithBootstrapButtons.fire({
                title: t("Eliminado"),
                text: "",
                icon: "success",
              });
            }

            const data = await response.json();
            setShowLoadingModal(false);
            console.log("todas las ofertas", data.data);
          } catch (error) {
            Swal.fire(t("no se pueden eliminar ofertas"));
            setShowLoadingModal(false);
            setDeletingOffer(false);
          }
        }
      });
  };
  const handleCategoryChange = (e) => {
    console.log("e.target.files[0]: ", e.target.files[0]);
    setNewCatImg(e.target.files[0]);
    const imgPrev = URL?.createObjectURL(e.target.files[0]);
    console.log("imgPrev: ", imgPrev);
    setPrevnewCatImg(imgPrev);
  };
  const updateCategory = (item) => {
    setShowModalEditCat(true);
    console.log("itemof category change: ", item);
    setNewcatName(item.category_name);
    setNewCatImg(item.image_url);
    setPrevnewCatImg(item.image_url);
    setNewunit(item.heading);
    setCatId(item.category_id);
  };
  const uploadImageAndEditCategory = async () => {
    setUpCat(true);
    const token = localStorage.getItem("token");
    try {
      // Create a new FormData object
      const formData = new FormData();
      // Append the image file with key "media"
      formData.append("media", newCatImg);
      formData.append("category_id", catId);
      formData.append("category_name", newcatName);

      // Send a POST request with fetch
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/editProductCategory`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: formData,
        }
      );

      // Check if the request was successful
      if (!response.ok) {
        // If not successful, throw an error with the status text
        Swal.fire("Server Error");
        setUpCat(false);
      } else {
        Swal.fire({
          title: "Updated",
          icon: "success",
        });

        setShowModalEditCat(false);
        setNewcatName("");
        setNewCatImg("");
        setPrevnewCatImg("");
        setNewunit("");
        setCatId("");
        setUpCat(false);
        getCategory();
      }

      // If successful, return the response JSON data
      return await response.json();
    } catch (error) {
      // If an error occurs during the fetch, log the error
      console.error("Error:", error.message);
      // You can also throw the error to handle it in the calling code
      throw error;
    }
  };

  useEffect(() => {
    fetchOffers();
    getAllPorduct();
    getCategory();
    fetchAllOffers();
  }, [
    loadCat,
    creload,
    proOfLoad,
    remOffAll,
    applyOffAll,
    deletingOffer,
    deletingSOffer,
    page,
    showLoadingModal,
    deletingPertiOffer,
  ]);

  useEffect(() => {
    getAllPorduct();
  }, [page]);

  return (
    <>
      <div className="dashboard">
        <div className="billboard-page mt-5">
          <Container fluid>
            <Row>
              <div></div>
              <Col xs lg="12" col="12">
                <div className="news-header">
                  <div className="back-sec">
                    <img
                      src="./img/volver.png"
                      alt=""
                      onClick={() => {
                        navigate("/homepage");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <h3 className="main-title">
                    {t("Administración de la tienda")}
                  </h3>
                  <div className="search-icon" style={{ display: "flex" }}>
                    {searchActive && (
                      <div className="searchBarInput">
                        <input
                          className="search-tag-bar"
                          placeholder={t("Buscar")}
                          value={searchTerm}
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    )}
                    <FiSearch
                      className="search-icon-click"
                      onClick={() => {
                        setSearchActive(!searchActive);
                      }}
                    />
                  </div>
                </div>
                <div className="grid-list-sec">
                  <div className="radio-btns-sec">
                    <div className="left-radios-sec">
                      <div
                        className={`radio-sec ${
                          selectedOption === "Lista" ? "selected" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          value="Cuadrícula"
                          checked={selectedOption === "Cuadrícula"}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="test1">{t("Productos")}</label>
                      </div>
                      <div
                        className={`radio-sec ${
                          selectedOption === "Lista" ? "selected" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="test2"
                          name="radio-group"
                          value="Lista"
                          checked={selectedOption === "Lista"}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="test2">{t("Categorías")}</label>
                      </div>
                    </div>
                  </div>
                  <>
                    {selectedOption === "Cuadrícula" && (
                      <div className="billboard-btn-sec">
                        <button
                          className="green-btn"
                          onClick={() => {
                            navigate("/additem");
                          }}
                        >
                          {t("Agregar producto")}
                        </button>

                        <button
                          className="green-btn"
                          onClick={() => {
                            setOpen3(true);
                            setOpen2(false);
                            setOpen(false);
                          }}
                        >
                          {t("Agregar oferta")}
                        </button>
                      </div>
                    )}
                    {selectedOption === "Cuadrícula" || (
                      <div className="billboard-btn-sec">
                        <button
                          className="green-btn"
                          onClick={() => {
                            navigate("/addcategory");
                          }}
                        >
                          {t("añadir categoría")}
                        </button>
                        <button
                          className="green-btn"
                          onClick={() => {
                            setOpen3(true);
                          }}
                        >
                          {t("Agregar oferta")}
                        </button>
                      </div>
                    )}
                  </>
                </div>

                <div className="billboard-sec" id="adlt-table">
                  {selectedOption === "Cuadrícula" && (
                    <>
                      {/* <div className="pagination-for-virtualstore">
                        {page === 1 || (
                          <button
                            className="previous-page"
                            onClick={() => {
                              setPage(page - 1);
                            }}
                            disabled={page === 1}
                          >
                            <LiaAngleLeftSolid />
                          </button>
                        )}

                        <div className="currentpage-for-virtual-store">
                          {banner?.length < 1 || page}
                        </div>
                        {banner?.length < 10 || (
                          <button
                            className="successor-page"
                            onClick={() => {
                              setPage(page + 1);
                            }}
                            disabled={banner?.length < 10}
                          >
                            <LiaAngleRightSolid />
                          </button>
                        )}
                      </div> */}
                      {loadPro ? (
                        <ClipLoader color="#000000" />
                      ) : (
                        <DataGridDemo
                          banner={banner}
                          offers={offers}
                          setSelecedProDuctId={setSelecedProDuctId}
                          setOpen2={setOpen2}
                          setOpen={setOpen}
                          deleteProduct={(e) => {
                            deleteProduct(e);
                          }}
                        />
                      )}

                      {/* <div className="billboard-btn-sec">
                        <button
                          className="green-btn"
                          onClick={() => {
                            navigate("/additem");
                          }}
                        >
                          {t("Agregar producto")}
                        </button>

                        <button
                          className="green-btn"
                          onClick={() => {
                            setOpen3(true);
                            setOpen2(false);
                            setOpen(false);
                          }}
                        >
                          {t("Agregar oferta")}
                        </button>
                      </div> */}
                    </>
                  )}
                  <Row>
                    <Col xs md="3"></Col>
                    <Col md="6" xs={12}>
                      {selectedOption === "Lista" && (
                        // (
                        //     <div className="lista-content">
                        //         {category ? (
                        //             <>
                        //                 <div className="billboard-sec-inner">
                        //                     <div className="lista-titles">
                        //                         <div className="cat-name">{t('Categorías')}</div>
                        //                         <div className="cat-action">{t('Acción')}</div>
                        //                     </div>
                        //                     {loadCat ? (
                        //                         <div className="Loader d-flex justify-content-center align-items-center">
                        //                             <div className="spinner-border my-5" role="status">
                        //                                 <span className="sr-only"></span>
                        //                             </div>
                        //                             <div className="my-5"> &nbsp; {t('Cargando...')}</div>
                        //                         </div>
                        //                     ) : (
                        //                         category.length === 0 ? (
                        //                             <div>{t('Ninguna categoría disponible')}</div>
                        //                         ) : (
                        //                             <div className="data-collection">
                        //                                 {
                        //                                     category.map((item, i) => {
                        //                                         if (true) {
                        //                                             return (
                        //                                                 <div
                        //                                                     className="myTeamBanner billboard-cards"
                        //                                                     key={i}
                        //                                                 >
                        //                                                     <div className="cat-name ps-0">
                        //                                                         <div className="card-img">
                        //                                                             <img src={item.image_url} alt="" />
                        //                                                         </div>
                        //                                                         <div className="head-desc">
                        //                                                             <div className="card-heading">
                        //                                                                 <h3 className="virtual-store-head-3">{item.category_name}</h3>
                        //                                                             </div>
                        //                                                         </div>
                        //                                                     </div>
                        //                                                     <div className="delete-sec-table cat-action">
                        //                                                         <div className="head-desc">
                        //                                                             <div className="delete-button" onClick={() => { deleteCategory(item.category_id) }}>
                        //                                                                 <RiDeleteBin5Fill />
                        //                                                             </div>
                        //                                                             <div className="delete-button" onClick={() => { deleteCategory(item.category_id) }}>
                        //                                                                 <FaPenAlt />
                        //                                                             </div>

                        //                                                         </div>
                        //                                                     </div>
                        //                                                 </div>
                        //                                             );
                        //                                         }
                        //                                     })
                        //                                 }
                        //                             </div>
                        //                         )
                        //                     )}
                        //                 </div>
                        //                 <div className="billboard-btn-sec">
                        //                     <button
                        //                         className="green-btn"
                        //                         onClick={() => {
                        //                             navigate("/addcategory");
                        //                         }}
                        //                     >
                        //                         {t('añadir categoría')}
                        //                     </button>
                        //                     <button
                        //                         className="green-btn"
                        //                         onClick={() => {
                        //                             setOpen3(true);
                        //                         }}
                        //                     >
                        //                         {t('Agregar oferta')}

                        //                     </button>
                        //                 </div>
                        //             </>
                        //         ) : (
                        //             <div className="Loader d-flex justify-content-center align-items-center">
                        //                 <div className="spinner-border my-5" role="status">
                        //                     <span className="sr-only"></span>
                        //                 </div>
                        //                 <div className=" my-5"> &nbsp; {t('Cargando...')}</div>
                        //             </div>
                        //         )}
                        //     </div>
                        // )
                        <div className="lista-content">
                          {category ? (
                            <>
                              <div className="billboard-sec-inner4">
                                <ResponsiveGrid
                                  category={category}
                                  t={t}
                                  loadCat={loadCat}
                                  deleteCategory={deleteCategory}
                                  updateCategory={updateCategory}
                                />
                              </div>
                            </>
                          ) : (
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className=" my-5">
                                {" "}
                                &nbsp; {t("Cargando...")}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col xs md="3"></Col>
                  </Row>
                </div>
              </Col>
              <div>
                <Dialog
                  open={open2}
                  className="store-popup"
                  onClose={handleClose2}
                >
                  <DialogTitle
                    style={{
                      padding: "15px",
                      paddingBottom: "0",
                      fontFamily: "Francois One",
                    }}
                  >
                    {t("Seleccionar oferta")}
                  </DialogTitle>
                  <DialogContent style={{ padding: "15px" }}>
                    <span className="yello-bg">
                      *{t("Se aplica al producto seleccionado")}*
                    </span>
                    <div className="btton-remove-offer">
                      <button
                        className="seperate-option-tag red-btn"
                        onClick={() => {
                          removeProductOffers();
                        }}
                      >
                        {deletingSOffer ? (
                          <div
                            className=""
                            style={{
                              height: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="loading-adjust">
                              <div className="Loader d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm my-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                                <div className="my-5">
                                  {" "}
                                  &nbsp; {t("Cargando...")}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          t("Eliminar oferta")
                        )}
                      </button>
                    </div>

                    <DialogContentText>
                      <p className="running-offers-dialog">
                        {t("Ofertas vigentes en el producto")}
                      </p>

                      <div className="select-tag-offer">
                        {offers.filter(
                          (item2) => item2.product_id === selecedProDuctId
                        ).length === 0 ? (
                          <p className="no-offer-notification">
                            * {t("Datos no encontrados")} *
                          </p>
                        ) : (
                          offers.map(
                            (item2, i) =>
                              item2.product_id === selecedProDuctId && (
                                <button
                                  className={`separate-option-tag`}
                                  key={i}
                                >
                                  <div className="delete-offer-button">
                                    <MdDelete
                                      onClick={() => {
                                        deletePerticularOfferFormProduct(
                                          item2.offer.offer_id,
                                          selecedProDuctId
                                        );
                                      }}
                                    />
                                  </div>
                                  {item2.offer.discount_percentage}%{" "}
                                  <div
                                    className=""
                                    onClick={() => {
                                      setSelectedDateForm2(item2, i);
                                    }}
                                  >
                                    <p className="button-date-show">
                                      {t("Comenzar")}:{" "}
                                      {formatDate(item2.offer.start_date)}
                                    </p>
                                    <p className="button-date-show">
                                      {t("Fin")}:{" "}
                                      {formatDate(item2.offer.end_date)}
                                    </p>
                                    <p className="button-date-show">
                                      {" "}
                                      {item2.offer.name}
                                    </p>
                                  </div>
                                </button>
                              )
                          )
                        )}
                      </div>
                    </DialogContentText>
                    <DialogContentText>
                      <p className="running-offers-dialog">
                        {t("Ofertas disponibles")}
                      </p>
                      <div className="select-tag-offer">
                        {isRunOff ? (
                          offers2?.map((item, index) => (
                            <button
                              className={`separate-option-tag ${
                                selectedIdx === index ? "selected" : ""
                              }`}
                              key={index}
                            >
                              <div className="delete-offer-button">
                                <MdDelete
                                  onClick={() => {
                                    deleteOffer(item);
                                  }}
                                />
                              </div>
                              {item.discount_percentage}%{" "}
                              <div
                                className=""
                                onClick={() => {
                                  setSelectedDateForm2(item, index);
                                }}
                              >
                                <p className="button-date-show">
                                  {t("Comenzar")}: {formatDate(item.start_date)}
                                </p>
                                <p className="button-date-show">
                                  {t("Fin")}: {formatDate(item.end_date)}
                                </p>
                                <p className="button-date-show"> {item.name}</p>
                              </div>
                            </button>
                          ))
                        ) : (
                          <p className="no-offer-notification">
                            *{" "}
                            {t(
                              "No hay ofertas vigentes, agregue ofertas para ver"
                            )}{" "}
                            *
                          </p>
                        )}
                      </div>
                    </DialogContentText>

                    <div className="add-offer-outer">
                      <button
                        className="add-offer blue-btn"
                        onClick={(e) => {
                          setOpen3(true);
                          setOpen(false);
                          setOpen2(false);
                        }}
                      >
                        {t("Agregar oferta")}
                        <IoMdAddCircle />
                      </button>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          marginTop: "30px",
                          marginBottom: "30px",
                        }}
                      >
                        {t("Oferta seleccionada")}{" "}
                        <span className="selectedOffer-percentage">
                          {" "}
                          {selectedOffer} &nbsp; %{" "}
                        </span>{" "}
                        <BiReset
                          onClick={() => {
                            setSelectedOffer("");
                            setSelectedIdx("");
                          }}
                        />
                      </p>
                    </div>
                  </DialogContent>
                  <DialogActions style={{ padding: "15px", paddingTop: "0" }}>
                    <Button
                      onClick={() => {
                        setOpen2(false);
                      }}
                      className="red-btn"
                    >
                      {t("Cancelar")}{" "}
                    </Button>
                    <Button
                      onClick={() => {
                        applyToProductOffers();
                      }}
                      className="green-btn"
                    >
                      {proOfLoad ? (
                        <div className="loading-adjust">
                          <div className="Loader d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border-sm my-5"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                            <div className="my-5">
                              {" "}
                              &nbsp; {t("Eliminando...")}
                            </div>
                          </div>
                        </div>
                      ) : (
                        t("Aplicar")
                      )}
                    </Button>
                  </DialogActions>
                </Dialog>
                <div className="dialog-box-mui">
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    style={{ backdropFilter: "blur(5px)" }}
                    className="store-popup"
                  >
                    <DialogTitle
                      style={{
                        padding: "15px",
                        paddingBottom: "0",
                        fontFamily: "Francois One",
                      }}
                    >
                      {t("Seleccionar oferta")}
                    </DialogTitle>
                    <DialogContent style={{ padding: "15px" }}>
                      <span className="yello-bg">
                        *{t("Se aplica universalmente a todos.")}*
                      </span>
                      <div className="btton-remove-offer">
                        <button
                          className="seperate-option-tag red-btn"
                          onClick={() => {
                            removeAllOffers();
                          }}
                          disabled={remOffAll}
                        >
                          {remOffAll ? (
                            <div className="loading-adjust">
                              <div className="Loader d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm my-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                                <div className="my-5">
                                  {" "}
                                  &nbsp; {t("Aplicando...")}
                                </div>
                              </div>
                            </div>
                          ) : (
                            t("Eliminar ofertas de todos los productos")
                          )}{" "}
                        </button>
                      </div>

                      <DialogContentText>
                        <p className="running-offers-dialog">
                          {t("ofertas en ejecución")}
                        </p>
                        <div className="select-tag-offer">
                          {isRunOff ? (
                            offers2.map((item, index) => (
                              <button
                                className={`separate-option-tag ${
                                  selectedIdx === index ? "selected" : ""
                                }`}
                                key={index}
                              >
                                <div className="delete-offer-button">
                                  <MdDelete
                                    onClick={() => {
                                      deleteOffer(item);
                                    }}
                                  />
                                </div>
                                {item.discount_percentage}%{" "}
                                <div
                                  className=""
                                  onClick={() => {
                                    setSelectedDateForm2(item, index);
                                  }}
                                >
                                  <p className="button-date-show">
                                    {t("Comenzar")}:{" "}
                                    {formatDate(item.start_date)}
                                  </p>
                                  <p className="button-date-show">
                                    {t("Fin")}: {formatDate(item.end_date)}
                                  </p>
                                  <p className="button-date-show">
                                    {" "}
                                    {item.name}
                                  </p>
                                </div>
                              </button>
                            ))
                          ) : (
                            <p className="no-offer-notification">
                              *{" "}
                              {t(
                                "No hay ofertas vigentes, agregue ofertas para ver"
                              )}{" "}
                              *
                            </p>
                          )}
                        </div>
                      </DialogContentText>

                      <div className="add-offer-outer">
                        <button
                          className="add-offer blue-btn"
                          onClick={(e) => {
                            setOpen3(true);
                            setOpen2(false);
                            setOpen(false);
                            addOfferClicked();
                          }}
                        >
                          {t("Agregar oferta")} <IoMdAddCircle />
                        </button>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                        >
                          {t("Oferta seleccionada")}{" "}
                          <span className="selectedOffer-percentage">
                            {" "}
                            {selectedOffer} &nbsp; %{" "}
                          </span>{" "}
                          <BiReset
                            onClick={() => {
                              setSelectedOffer("");
                              setSelectedIdx("");
                            }}
                          />
                        </p>
                      </div>
                    </DialogContent>
                    <DialogActions style={{ padding: "15px", paddingTop: "0" }}>
                      <Button onClick={handleClose} className="red-btn">
                        {t("Cancelar")}
                      </Button>
                      <Button
                        onClick={() => {
                          applyToAllOffers();
                        }}
                        className="green-btn"
                      >
                        {" "}
                        {applyOffAll ? (
                          <div className="loading-adjust">
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className="my-5">
                                {" "}
                                &nbsp; {t("Aplicando...")}
                              </div>
                            </div>
                          </div>
                        ) : (
                          t("Aplicar a todo")
                        )}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className="dialog-box-mui">
                  <Dialog
                    open={open3}
                    onClose={handleClose3}
                    style={{ backdropFilter: "blur(5px)" }}
                    className="store-popup"
                  >
                    <DialogTitle
                      style={{
                        padding: "15px",
                        paddingBottom: "0",
                        fontFamily: "Francois One",
                      }}
                    >
                      {t("Crear oferta")}
                    </DialogTitle>
                    <DialogContent
                      style={{ padding: "15px", fontFamily: "Francois One" }}
                    >
                      <div className="input-div">
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label={t("Oferta (%)")}
                          type="number"
                          pattern="\d{1,3}"
                          fullWidth
                          variant="standard"
                          className="text-field-mui"
                          value={custOffer}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setCustOffer(e.target.value);
                            } else {
                              if (e.target.value > 100) {
                                window.alert("Number only between 1-100");
                                return;
                              }
                              if (e.target.value < 1) {
                                window.alert("Number only between 1-100");
                                return;
                              } else {
                                setCustOffer(e.target.value);
                              }
                            }
                          }}
                          style={{ margin: "0", fontFamily: "Francois One" }}
                        />
                      </div>
                      <div className="input-div">
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label={t("Nombre de la oferta")}
                          type="text"
                          pattern="\d{1,3}"
                          fullWidth
                          variant="standard"
                          className="text-field-mui"
                          value={offerName}
                          onChange={(e) => {
                            setOfferName(e.target.value);
                          }}
                          style={{ margin: "0", fontFamily: "Francois One" }}
                        />
                      </div>

                      <div className="input-div">
                        <InputLabel htmlFor="input" autoFocus>
                          {t("Fecha de inicio")}
                        </InputLabel>
                        <Input
                          autoFocus
                          margin="dense"
                          id="input"
                          label={t("Fecha de inicio")}
                          type="date"
                          fullWidth
                          variant="standard"
                          value={startDate}
                          onChange={(e) => {
                            const selectedDate = e.target.value;
                            const today = new Date()
                              .toISOString()
                              .split("T")[0];

                            if (selectedDate < today) {
                              alert(
                                "Selected date is before or equal to today"
                              );
                              return;
                            } else {
                              console.log("Selected date is after today");
                              setStartDate(selectedDate);
                            }
                          }}
                        />
                      </div>

                      <div className="input-div">
                        <InputLabel htmlFor="input" autoFocus>
                          {t("Fecha final")}
                        </InputLabel>
                        <Input
                          autoFocus
                          margin="dense"
                          id="input"
                          label={t("fecha de inicio")}
                          type="date"
                          min={new Date().toISOString().split("T")[0]} // Set min to today's date
                          fullWidth
                          variant="standard"
                          value={endDate}
                          onChange={(e) => {
                            const selectedDate = e.target.value;
                            const today = new Date()
                              .toISOString()
                              .split("T")[0];

                            if (selectedDate <= today) {
                              alert(
                                "Selected date is before or equal to today"
                              );
                              return;
                            } else {
                              console.log("Selected date is after today");
                              setEndDate(selectedDate);
                            }
                          }}
                        />
                      </div>

                      <div></div>
                    </DialogContent>
                    <DialogActions style={{ padding: "15px", paddingTop: "0" }}>
                      <Button onClick={handleClose3} className="red-btn">
                        {t("Cancelar")}
                      </Button>
                      <Button
                        onClick={(e) => {
                          setOffersui();
                        }}
                        disabled={creload}
                        className="green-btn"
                      >
                        {t("Crear")}
                      </Button>
                    </DialogActions>
                    {creload ? (
                      <div className="Loader d-flex justify-content-center align-items-center">
                        <div className="spinner-border my-5" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <div className=" my-5"> &nbsp; {t("Cargando...")}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Dialog>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <Modal
          show={showModalEditCat}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div
              className=""
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="loading-adjust">
                <div className="Loader d-flex justify-content-center align-items-center">
                  <div
                    className="spinner-border spinner-border-sm my-5"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                  <div className="my-5"> &nbsp; {t("Eliminando...")}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>{t("Eliminando Por favor espere")}</p>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalEditCat}
          // show={true}
          onHide={handleCloseEditCat}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <div className="EditCategory-heading">{t("Editar categoria")}</div>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-imag-and-heading">
              <div className="img-w-prev">
                <div className="upload-img-form">
                  {prevnewCatImg ? (
                    <div>
                      <div className="img-w-delete">
                        <img src={prevnewCatImg} alt="category" />
                        <MdDelete
                          onClick={() => {
                            setPrevnewCatImg("");
                            setNewCatImg("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <FaImage
                      style={{ width: "150px", height: "150px" }}
                      className="sample-image"
                    />
                  )}
                  <FiUpload
                    className="icon-only"
                    onClick={handleChangeCatInput}
                  />
                </div>

                <div className="input-and-icon">
                  <input
                    className="input-only"
                    value={newcatName}
                    placeholder="category name"
                    onChange={(e) => {
                      setNewcatName(e.target.value);
                    }}
                  />
                  <input value={`unit:- ${newUnit}`} disabled={true} />
                </div>

                <div className="modal-unit-div"></div>
              </div>
              <input
                ref={useRefCat}
                className="input-changecat-img"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  handleCategoryChange(e);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="green-btn"
              onClick={() => {
                uploadImageAndEditCategory();
              }}
            >
              {upCat ? (
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loading-adjust">
                    <div className="Loader d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border spinner-border-sm my-5"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                      <div className="my-5"> &nbsp; {t("Cargando...")}</div>
                    </div>
                  </div>
                </div>
              ) : (
                t("Save")
              )}
            </button>
            <button
              className="red-btn"
              onClick={() => {
                setShowModalEditCat(false);
              }}
            >
              {t("Decline")}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default VIrtualStore;
