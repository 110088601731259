import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/Dashboard/HomePage/HomePage";
import FullLayout from "./components/FullLayout/FullLayout";
import LoginScreen from "./pages/LoginPages/LoginScreen";
import DigitalSec from "./pages/Dashboard/DigitalSec/DigitalSec";
import AdminPage from "./pages/AdminPage/AdminPage";
import News from "./pages/News/News";
import NewsAdd from "./pages/News/NewsAdd";
import EditNews from "./pages/News/EditNews";
import EventPage from "./pages/EventPage/EventPage";
import EventDate from "./pages/EventPage/EventDate";
import ExperiencePage from "./pages/ExperiencePage/ExperiencePage";
import AddExperience from "./pages/ExperiencePage/AddExperience";
import EditExp from "./pages/ExperiencePage/EditExp";
import LagendPage from "./pages/LegendPage/LagendPage";
import AddLagend from "./pages/LegendPage/AddLagend";
import MultimediaImages from "./pages/MulmtimediaImages/MultimediaImages";
import AddMultimedia from "./pages/MulmtimediaImages/AddMultimedia";
import StreamingPage from "./pages/StreamingPage/StreamingPage";
import AddStreaming from "./pages/StreamingPage/AddStreaming";
import ProposalAndVotes from "./pages/ProposalAndVotes/ProposalAndVotes";
import AddProposalVotez from "./pages/ProposalAndVotes/AddProposalVotez";
import TeamAdministration from "./pages/Dashboard/TeamAdministration/TeamAdministration";
import TeamAdministrationProfile from "./pages/Dashboard/TeamAdministration/TeamAdministrationProfile";
import DigitalManager from "./pages/Dashboard/DigitalSec/DigitalManager";
import "./fonts.css";
import DigitalLeader from "./pages/DigitalLeaders/DigitalLeader";
import Userprofile from "./pages/DigitalLeaders/UserProfile";
import DigitalSec2 from "./pages/Dashboard/DigitalSec/DigitalSec2/DigitalSec";
import "./App.css";
import JuvenliesPlants from "./pages/Dashboard/TeamAdministration/JuvenliesPlants";
import BoardMeeting from "./pages/DebateRoom/BoardMeeting/BoardMeeting";
import Proposalvotesbadgeedit from "./pages/ProposalAndVotes/Proposals&Voteseditbadge";
import DigitalManagerOne from "./pages/Dashboard/DigitalSec/DigitalManagerOne";
import DigitalManagerTwo from "./pages/Dashboard/DigitalSec/DigitalManagerTwo";
import Reports from "./pages/ReportsComplaints/reports";
import Calendar from "./pages/Calender/calender";
import Billboard from "./pages/Billboard/Billboard";
import BillboardAddPost from "./pages/Billboard/BillboardAddPost";
import EditorBannerPage from "./pages/Billboard/EditorBannerPage";
import VirtualStore from "./pages/VirtualStore/VirtualStore";
import BillboardPost from "./pages/Billboard/BillboardPost";
import Addproduct from "./pages/VirtualStore/AddProduct/addproduct";
import AddCategory from "./pages/VirtualStore/AddCategory/addCategory";
import ProductDeails from "./pages/VirtualStore/ProductDetail/ProductDetail";
import Terms from "./pages/PrivacyPolicy/PrivacyPolicy";
import ChatCompList from "./pages/DebateRoom/ChatCompList/ChatCompList";
import ChatHistory from "./pages/DebateRoom/ShowChats/ShowChats";
import GroupChatHistory from "./pages/DebateRoom/BoardMeeting/DebateChatRoom";
import AddItem from "./pages/VirtualStore/AddProduct/includeProduct";
import EventDateEdit from "./pages/EventPage/EventDateEdit";
import Emails from "./pages/Emails/Emails";
import Testing from "./pages/testing/testing";
import BarChart from "./charts/barchart";
import Photo from "./pages/imageUploading/photo";
// import FirebaseAutoRefreshExample from "./pages/firetest";
import ProtectedRoute from "./protectroute";
import Editproduct from "./pages/VirtualStore/AddProduct/editProduct";
import Editproduct2 from "./pages/VirtualStore/EditProduct/editProduct";
import ForgetPass from "./pages/ForgetPass/forgetPass";
import ResetPassword from "./pages/resetPassword/resetpassword";

function App() {
  return (
    <Router basename="">
      <div className="app">
        <Routes>
          <Route index element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={
              <>
                <LoginScreen />
              </>
            }
          />
          <Route
            path={`/forgetpass`}
            element={
              <>
                <ForgetPass />
              </>
            }
          />
          <Route
            path={`/reset-password/:token`}
            element={
              <>
                <ResetPassword />
              </>
            }
          />

          <Route
            path="/"
            element={
              <>
                {" "}
                <FullLayout />{" "}
              </>
            }
          >
            <Route
              path="/homepage"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <HomePage />
                    </>
                  }
                />
              }
            />

            <Route
              path="/digitalsection"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <DigitalSec />
                    </>
                  }
                />
              }
            />

            <Route
              path="/gemshistory"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <DigitalLeader />
                    </>
                  }
                />
              }
            />

            <Route
              path="/leaderprofile"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Userprofile />
                    </>
                  }
                />
              }
            />

            <Route
              path="/reports"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Reports />
                    </>
                  }
                />
              }
            />

            <Route
              path="/emails"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Emails />
                    </>
                  }
                />
              }
            />

            <Route
              path="/calender"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Calendar />
                    </>
                  }
                />
              }
            />

            <Route
              path="/termsandcondition"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Terms />
                    </>
                  }
                />
              }
            />

            <Route
              path="/testing"
              element={
                <ProtectedRoute
                  element={
                    <>
                      <Testing />
                    </>
                  }
                />
              }
            />

            <Route
              path="/debateroom"
              element={<ProtectedRoute element={<ChatCompList />} />}
            />

            <Route
              path="/chathistory"
              element={<ProtectedRoute element={<ChatHistory />} />}
            />

            <Route
              path="/debatechatroom"
              element={<ProtectedRoute element={<GroupChatHistory />} />}
            />

            <Route
              path="/billboard"
              element={<ProtectedRoute element={<Billboard />} />}
            />

            <Route
              path="/addbillboard"
              element={<ProtectedRoute element={<BillboardAddPost />} />}
            />

            <Route
              path="/editbillboard"
              element={<ProtectedRoute element={<EditorBannerPage />} />}
            />

            <Route
              path="/viewbillboard"
              element={<ProtectedRoute element={<BillboardPost />} />}
            />

            <Route
              path="/store"
              element={<ProtectedRoute element={<VirtualStore />} />}
            />
            <Route
              path="/editproduct"
              element={<ProtectedRoute element={<Editproduct />} />}
            />
            <Route
              path="/editproduct2"
              element={<ProtectedRoute element={<Editproduct2 />} />}
            />

            <Route
              path="/addcategory"
              element={<ProtectedRoute element={<AddCategory />} />}
            />

            <Route
              path="/productdetails"
              element={<ProtectedRoute element={<ProductDeails />} />}
            />

            <Route
              path="/addproduct"
              element={<ProtectedRoute element={<Addproduct />} />}
            />

            <Route
              path="/additem2"
              element={<ProtectedRoute element={<AddItem />} />}
            />
            <Route
              path="/additem"
              element={<ProtectedRoute element={<Addproduct />} />}
            />

            <Route
              path="/addevent"
              element={<ProtectedRoute element={<EventPage />} />}
            />

            <Route
              path="/editevent"
              element={<ProtectedRoute element={<EventDateEdit />} />}
            />

            <Route
              path="/events"
              element={<ProtectedRoute element={<EventDate />} />}
            />

            <Route
              path="/experiencepage"
              element={<ProtectedRoute element={<ExperiencePage />} />}
            />

            <Route
              path="/addexperience"
              element={<ProtectedRoute element={<AddExperience />} />}
            />

            <Route
              path="/editexperience"
              element={<ProtectedRoute element={<EditExp />} />}
            />

            <Route
              path="/addmedia"
              element={<ProtectedRoute element={<AddMultimedia />} />}
            />

            <Route
              path="/streaming"
              element={<ProtectedRoute element={<StreamingPage />} />}
            />

            <Route
              path="/addstreaming"
              element={<ProtectedRoute element={<AddStreaming />} />}
            />

            <Route
              path="/proposalvote"
              element={<ProtectedRoute element={<ProposalAndVotes />} />}
            />

            <Route
              path="/uploadambassador"
              element={<ProtectedRoute element={<Photo />} />}
            />

            <Route
              path="/addproposalvote"
              element={<ProtectedRoute element={<AddProposalVotez />} />}
            />

            <Route
              path="/editproposalvote"
              element={<ProtectedRoute element={<Proposalvotesbadgeedit />} />}
            />

            <Route
              path="/multimedia"
              element={<ProtectedRoute element={<MultimediaImages />} />}
            />
          </Route>
          <Route
            path="/contactadmin"
            element={<ProtectedRoute element={<AdminPage />} />}
          />

          {/* <Route path="/news" element={<News />}></Route> */}
          {/* <Route path="/addnews" element={<NewsAdd />}></Route> */}
          {/* <Route path="/editnews" element={<EditNews />}></Route> */}
          {/* <Route path="/reachadmin" element={<AdminPage />} /> */}
          {/* <Route path="/digital2" element={<DigitalSec2 />} /> */}
          {/* <Route path="/sample1" element={<DigitalManagerOne />} />
            <Route path="/sample2" element={<DigitalManagerTwo />} /> */}
          {/* <Route path="/legendpage" element={<LagendPage />}></Route> */}
          {/* <Route path="/addlegend" element={<AddLagend />}></Route> */}
          {/* <Route path="/chart" element={<BarChart />}></Route> */}
          {/* <Route
              path="/teamadministration"
              element={<TeamAdministration />}
            ></Route> */}

          {/* <Route
              path="/teamadministrationprofile"
              element={<TeamAdministrationProfile />}
            ></Route> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
