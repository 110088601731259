import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FaPenAlt } from "react-icons/fa";
import "./showCategory.css";
import { fontGrid } from "@mui/material/styles/cssUtils";

const CategoryGrid = ({
  category,
  loadCat,
  t,
  deleteCategory,
  updateCategory,
}) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const [search, setSearch] = useState("");
  const [categorymap, setCategorymap] = useState([]);

  const handleSearch = () => {
    const filtered = category.filter((item) =>
      item.category_name.toLowerCase().includes(search.toLowerCase())
    );
    setCategorymap(filtered);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setCategorymap(category);
  }, [category]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          background: "transparent",
          borderRadius: "8px",
          overflowY: "scroll",
          maxHeight: "38rem",
        }}
      >
        {loadCat ? (
          // Loader for Loading State
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="300px"
          >
            <CircularProgress />
            <Typography variant="body1" ml={2} sx={{ color: "#555" }}>
              {t("Cargando...")}
            </Typography>
          </Box>
        ) : categorymap?.length === 0 ? (
          // No Data Available
          <Box textAlign="center" minHeight="300px">
            <Typography variant="h6" sx={{ color: "#777" }}>
              {t("Ninguna categoría disponible")}
            </Typography>
          </Box>
        ) : (
          // Category Grid
          <Grid container spacing={2}>
            {categorymap?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    position: "relative",
                    height: "100%",
                    boxShadow: "0 8px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: "16px",
                    overflow: "hidden",
                    border: "1px solid #ffffff3d",
                    background: "transparent",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 16px 24px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  {/* Actions */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    m={1}
                    // mb={1}
                    gap={2}
                    sx={{
                      "& .MuiIconButton-root": {
                        background: "transparent",
                        transition: "background 0.3s",
                        "&:hover": {
                          background: "transparent",
                          boxShadow: "0px 0px 5px #ffffff3d",
                        },
                      },
                    }}
                  >
                    <Tooltip title={t("Borrar")} placement="top">
                      <IconButton
                        sx={{
                          border: "1px solid #ffffff30",
                          "&:hover": {
                            boxShadow: "0px 0px 3px #ffffff3d",
                            background: "transparent",
                          },
                        }}
                        onClick={() => deleteCategory(item.category_id)}
                      >
                        <RiDeleteBin5Fill fill="#f95151" size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Editar")} placement="top">
                      <IconButton
                        color="primary"
                        sx={{
                          border: "1px solid #ffffff30",
                          "&:hover": {
                            boxShadow: "0px 0px 3px #ffffff3d",
                            background: "transparent",
                          },
                        }}
                        onClick={() => updateCategory(item)}
                      >
                        <FaPenAlt fill="#ffffff80" size={20} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {/* Image with Gradient Overlay */}
                  <Box sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      height="160"
                      image={item.image_url}
                      alt={item.category_name}
                      sx={{
                        objectFit: "contain",
                        filter: "brightness(1)",
                        width: "60%",
                        margin: "0 auto",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "50%",
                      }}
                    />
                  </Box>

                  {/* Content */}
                  <CardContent
                    sx={{
                      position: "relative",
                      zIndex: 2,
                      textAlign: "center",
                      backgroundColor: "transparent",
                      padding: "6px",
                      borderRadius: "0 0 16px 16px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        color: "#c3c3c3",
                        fontamily: "circular",
                        marginBottom: "8px",
                        fontSize: "16px",
                      }}
                    >
                      {item.category_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CategoryGrid;
