import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import translations from "../../../locals/translation";
import { useSelector } from "react-redux";

export default function AccessibleTable({ rows, handleEdit }) {
  const language = useSelector((state) => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };
  const cellStyle = {
    color: "white",
    fontSize: "1rem",
    fontFamily: "Century-BoldItalic",
  };
  const bodyCellStyle = { color: "white", fontSize: "1rem" };
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "10px",
        background: "transparent",
        color: "white",
        border: "2px solid",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow sx={{ background: "#3b3b3b", color: "white" }}>
            <TableCell sx={cellStyle} align="center">
              {t("Tamaño")}
            </TableCell>
            <TableCell sx={cellStyle} align="center">
              {t("Color")}
            </TableCell>
            <TableCell sx={cellStyle} align="center">
              {t("Cantidad")}
            </TableCell>
            <TableCell sx={cellStyle} align="center">
              {t("Comportamiento")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: "20rem", overflowY: "auto" }}>
          {rows.length > 0 ? (
            rows.map((row) => (
              <>
                {console.log("row.rowId: ", row.rowId)}
                <TableRow key={row.rowId}>
                  <TableCell sx={bodyCellStyle} align="center">
                    {row.Size}
                  </TableCell>
                  <TableCell sx={bodyCellStyle} align="center">
                    {row.Color}
                  </TableCell>
                  <TableCell sx={bodyCellStyle} align="center">
                    {row.Quantity}
                  </TableCell>
                  <TableCell
                    sx={bodyCellStyle}
                    align="center"
                    onClick={() => handleEdit(row.id)}
                  >
                    {row.Action}
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow sx={cellStyle} align="center">
              <TableCell sx={cellStyle} align="center"></TableCell>
              <TableCell sx={cellStyle} align="right">
                {" "}
                {t("No hay variantes existentes")}
              </TableCell>
              <TableCell sx={cellStyle} align="center">
                {" "}
              </TableCell>
              <TableCell sx={cellStyle} align="center">
                {" "}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
