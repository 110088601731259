import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";

import Table from "react-bootstrap/Table";

import "./ProductDetails.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdColorFill } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import GlobalURL from "../../BaseUrl";

// card MUI
import { useSelector } from "react-redux";
import translations from "../../../locals/translation";

const ProductDetails = () => {
  const language = useSelector((state) => state.language.value);

  const [availableSizes, setAvailableSizes] = useState([]);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state.product_id);
  const id = location.state.product_id;
  const [data, setData] = useState([]);
  const [loadPro, setLoadPro] = useState(true);
  const [scID, setScID] = useState("");
  const [selectedColor, setSelectedColor] = useState(""); // State to manage the selected color

  const [product, setProduct] = useState([]);

  const fetchDetails = async () => {
    const token2 = localStorage.getItem("token");
    console.log("token", typeof token2);
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/store/getProductDetail`,
        {
          method: "POST", // or 'POST' or any other HTTP method
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token2}`,

            // 'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`,
          },
          body: JSON.stringify({ product_id: id }),
        }
      );

      if (!response.ok) {
        Swal.fire("Error del Servidor");
      }
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }

      const data = await response.json();
      setData(data);
      setProduct(data.data);
      console.log("API Response:", data.data);

      setScID(data.data.product_colors[0].product_color_id);
      setSelectedColor(data.data.product_colors[0].colorName);

      setLoadPro(false);
    } catch (error) {
      // Handle errors that occurred during the fetch
      console.error("Error fetching data:", error.message);
    }
  };

  function extractDate(input) {
    const date = new Date(input);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Function to extract time from the input string
  function extractTime(input) {
    const date = new Date(input);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    if (selectedColor) {
      // Find the color ID from the selected color name
      const selectedColorObject = product.product_colors.find(
        (color) => color.colorName === selectedColor
      );
      if (selectedColorObject) {
        const colorId = selectedColorObject.product_color_id;
        // Filter sizes based on the selected color ID
        const sizesForColor = product.product_quantities
          .filter((quantity) => quantity.product_color_id === colorId)
          .map((quantity) => {
            const sizeObject = product.product_sizes.find(
              (size) => size.product_size_id === quantity.product_size_id
            );
            return sizeObject ? sizeObject.size : null;
          })
          .filter((size) => size !== null);

        setAvailableSizes(sizesForColor);
      } else {
        setAvailableSizes([]);
      }
    }
  }, [selectedColor, product]);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <Container fluid>
            <Row>
              <Col xs lg="3"></Col>
              <Col xs lg="6">
                <div className="exp-editPage">
                  <div className="news-header">
                    <div className="back-sec">
                      <img
                        src="./img/volver.png"
                        alt=""
                        onClick={() => {
                          navigate("/store");
                        }}
                      />
                    </div>
                    <h3 className="main-title">{t("Detalles de producto")}</h3>
                    <div className="search-icon">
                      <FiSearch style={{ opacity: "0" }} />
                    </div>
                  </div>
                  {loadPro ? (
                    <div className="Loader d-flex justify-content-center align-items-center">
                      <div className="spinner-border my-5" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div className=" my-5"> &nbsp; {t("Eliminando...")}</div>
                    </div>
                  ) : (
                    <>
                      <div className="twosections-proDetails">
                        <div className="leftsectionProDetail">
                          <div className="details-slider">
                            {selectedColor === "" ? (
                              "Select Color"
                            ) : (
                              <Swiper
                                pagination={{
                                  type: "fraction",
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                              >
                                {product.product_images
                                  .filter(
                                    (image) => image.product_color_id === scID
                                  ) // Filter images based on selected product_color_id
                                  .map((image, index) => (
                                    <SwiperSlide key={index}>
                                      <div>
                                        <img
                                          src={image?.imageUrl}
                                          alt={`Product-display ${index + 1}`}
                                          className="product-image"
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                              </Swiper>
                            )}
                          </div>
                        </div>
                        <div className="rightsecProdetail">
                          <h2 className="product-name">{product.name}</h2>
                          {/* <h5 className='description-heading'>{t('Descripción')}</h5> */}
                          <p className="product-description">
                            {" "}
                            {product.description}
                          </p>
                        </div>
                      </div>
                      <div className="color-details">
                        <div className="color-circles">
                          <div className="image-colout-card">
                            <div className="name-color">
                              {product.product_colors.map((color) => (
                                <div
                                  className={`colour-card-showdetails ${
                                    scID === color.product_color_id
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setScID(color.product_color_id);
                                    setSelectedColor(color.colorName);
                                  }}
                                >
                                  <div className="pro-Image-aligned">
                                    {Array.from(
                                      new Set(
                                        product.product_images.map(
                                          (img) => color.product_color_id
                                        )
                                      )
                                    ).map((colorId, key) => (
                                      // Rendering images for each unique product_color_id
                                      <div key={key} className="image-color">
                                        <img
                                          src={
                                            product.product_images.find(
                                              (img) =>
                                                img.product_color_id === colorId
                                            )?.imageUrl
                                          }
                                          alt="img"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="name-colour">
                                      {" "}
                                      {color.colorName}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="product-view-container">
                        <div className="product-details">
                          <p className="product-category">
                            {product?.product_category?.heading}
                          </p>
                          {/* <div className="sizesMap">
                            {product.product_sizes.map((item, key) => (
                              <p key={key} className="size-available-units">
                                {item?.size}
                              </p>
                            ))}
                          </div>
                          <p className="product-category">
                            {t("Cantidades")} ({selectedColor})
                          </p> */}

                          <div>
                            <div className="sizesMap">
                              {availableSizes.map((size, key) => (
                                <p key={key} className="size-available-units">
                                  {size}
                                </p>
                              ))}
                            </div>
                            <p className="product-category">
                              {t("Cantidades")} ({selectedColor})
                            </p>
                          </div>

                          <div className="sizesMap">
                            {product?.product_sizes?.map((item, key) =>
                              product?.product_quantities?.map(
                                (item2, key2) => (
                                  <>
                                    {item?.product_size_id ===
                                      item2?.product_size_id &&
                                      scID === item2?.product_color_id && (
                                        <p className="size-available-units">
                                          {item2?.quantity}
                                        </p>
                                      )}
                                  </>
                                )
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pd-table">
                        <Table>
                          <thead>
                            <tr style={{ background: "#ffffff1f" }}>
                              <th colSpan={2}>{t("Detalles de producto")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{t("Categoría")}</td>
                              <td>
                                {product?.product_category?.category_name}{" "}
                                <img
                                  className="product-category-image"
                                  src={product?.product_category?.image_url}
                                  alt="img"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Moneda base")}</td>
                              <td>{product?.baseCurrency}</td>
                            </tr>
                            <tr>
                              <td>{t("Precio descontado")}</td>
                              <td>{product?.discounted_price}</td>
                            </tr>
                            <tr>
                              <td>{t("Marca")}</td>
                              <td>{product.brand}</td>
                            </tr>
                            <tr>
                              <td>{t("Creado en")}</td>
                              <td>{`${t("Fecha")}: ${extractDate(
                                product?.createdAt
                              )}, ${t("Tiempo")}: ${extractTime(
                                product?.createdAt
                              )}`}</td>
                            </tr>
                            <tr>
                              <td>{t("Actualizado en")}</td>
                              <td>{`${t("Fecha")}: ${extractDate(
                                product?.createdAt
                              )}, ${t("Tiempo")}: ${extractTime(
                                product?.updatedAt
                              )}`}</td>
                            </tr>
                            <tr style={{ background: "#ffffff1f" }}>
                              <td colSpan={2}>{t("Detalles del proveedor")}</td>
                            </tr>
                            <tr>
                              <td>{t("Nombre")}</td>
                              <td>{product?.seller_name}</td>
                            </tr>
                            <tr>
                              <td>{t("Género")}</td>
                              <td>{product?.gender}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </div>
              </Col>
              <Col xs lg="3"></Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
