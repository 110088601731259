import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiPlus, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./style2.css";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import GlobalURL from "../BaseUrl";
import { AiOutlineLike } from "react-icons/ai";
import { GoComment } from "react-icons/go";
import { GoShare } from "react-icons/go";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { socket } from "../../socket";
import { BsCalendar3 } from "react-icons/bs";
import { IoCardOutline } from "react-icons/io5";
import { PiTriangle } from "react-icons/pi";
import { MdKeyboardVoice, MdOutlineKeyboardVoice } from "react-icons/md";
import { AudioRecorder } from "react-audio-voice-recorder";

const DigitalManager = () => {
  const { state } = useLocation();
  console.log("State ", state.user_id);

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const location = useLocation();
  const [userId, setUserId] = useState(state.user_id);
  const navigate = useNavigate();
  const [viewMembership] = useState("");
  const [isban, setIsban] = useState("");
  const [isVerify, setIsVerify] = useState("");
  const [shstrURL, setShstrURL] = useState("");
  // const [showStreamingURL, setShowStreamingURL] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [commentreply, setCommentreply] = useState([]);
  const [showChat, setShowChat] = useState(false);

  const [recordedFile, setRecordedFile] = useState(null);

  const [loadigComments, setLoadigComments] = useState(false);
  const [loadigCommentsReply, setLoadigCommentsReply] = useState(false);
  const [postIds, setPostIds] = useState([]);
  const [recorder, setRecorder] = useState(false);

  const [isDia, setIsDia] = useState(false);
  const [isGol, setIsGol] = useState(false);
  const [isPla, setIsPla] = useState(false);

  const [selGold, setSelGold] = useState("");
  const [selPlat, setSelPlat] = useState("");
  const [selDiam, setSelDiam] = useState("");

  const [infoVis, setInfoVis] = useState(true);
  const [cmtVis, setCmtVis] = useState(false);
  const [threeOp, setThreeOp] = useState(false);

  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [comments, setComments] = useState([]);

  const [isgActive, setIsgActive] = useState(false);
  // const [ispActive, setIspActive] = useState(false);
  // const [isdActive, setIsdActive] = useState(false);

  const [data, setData] = useState([]);

  const [insigniaAvailable, setInsigniaAvailable] = useState([
    // 'one': false,
    // 'two': false,
    // 'three': false,
    // 'four': false,
    // 'five': false,
    // 'six': false,
    // 'seven': false,
    // 'eight': false,
    // 'nine': false,
    // 'ten': false,
  ]);

  const [oro, setOro] = useState(0);
  const [plat, setPlat] = useState(0);
  const [diam, setDiam] = useState(0);

  const [groupChat, setGroupChat] = useState();
  const [messageTyped, setMessageTyped] = useState("");
  const lastMessageRef = useRef(null);
  const [userDetails, setUserDetails] = useState();
  const [media, setMedia] = useState(null);
  const [mediaURL, setMediaURL] = useState();
  const inputRef = useRef(null);
  const [isWait, setIsWait] = useState();
  const [chatBoxClicked, setChatBoxClicked] = useState(false);
  const formData = new FormData();

  const connectSocket = () => {
    setChatBoxClicked((prev) => !prev);
    document.querySelector(".chatBox-toggle").classList.toggle("showbox");
  };
  // connecting socket
  useEffect(() => {
    socket.connect();
    socket.emit("connect_user", { user_id: localStorage.getItem("user_id") });
    socket.on("direct_messages_list", (data) => {
      console.log("First required data:  ", data);

      let user_Details = data.users.find((item) => {
        // eslint-disable-next-line
        return item.user_id == userId;
      });
      console.log("UserDetail : ", user_Details);
      if (user_Details) setUserDetails(user_Details);
      else {
        // setGroupChat([]);
        setUserDetails([]);
      }
    });
    // eslint-disable-next-line
  }, [socket]);
  //get chat history (moderator and user)
  useEffect(() => {
    if (chatBoxClicked) {
      console.log("get msg history: UE called");
      console.log(
        "@userDetails?.inbox_details?.inbox_uid: ",
        userDetails?.inbox_details?.inbox_uid
      );
      socket.emit("get_message_history", {
        inbox_uid: userDetails?.inbox_details?.inbox_uid,
        receiverId: userId,
      });
      socket.on("message_history", (data) => {
        console.log("data getchat: ", data);
        if (data.messages?.length) {
          setGroupChat(data.messages);
        } else {
          setGroupChat([]);
        }
      });
      // socket.on('errorHandler', (data) => {
      //     if (data.includes('get_message_history')) {
      //         setGroupChat([]);
      //     }
      // })
      // eslint-disable-next-line
    }
  }, [userDetails, chatBoxClicked]);
  function formatDat2(expireDate) {
    const formattedDate = new Date(expireDate);

    // Extract month and year
    const month = formattedDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-indexed month
    const year = formattedDate.getFullYear() % 100; // Get last two digits of the year

    // Format month and year as MM/YY
    const formattedExpireDate = `${month.toString().padStart(2, "0")}/${year
      .toString()
      .padStart(2, "0")}`;

    return formattedExpireDate;
  }
  //listening incoming msg
  useEffect(() => {
    // Define the listener function
    const privateMessageListener = (data) => {
      console.log("listen continuously: ", data);
      if (data) setGroupChat((prev) => [...prev, data]);
      // setMessageTyped("");
    };

    // Attach the listener
    socket.on("privateMessage", privateMessageListener);

    // Clean up by removing the listener when component unmounts
    return () => {
      socket.off("privateMessage", privateMessageListener);
    };

    // Add dependencies to the dependency array
  }, [socket, setGroupChat]);

  //scroll to last msg after chats load
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [groupChat]);
  const renderChat = () => {
    console.log("chatsss:-- ", groupChat);
    if (groupChat) {
      if (groupChat.length) {
        return groupChat.map((msg, i) => {
          //check message_chat type (img,video,text)
          const checkMsgType = () => {
            // console.log('msg.message_type: ', msg.message_type);
            if (msg.message_type === "TEXT") {
              return <p>{msg?.message}</p>;
            } else if (msg.message_type === "IMAGE") {
              return (
                <img className="chat_img" alt="" src={msg.multimedia_url} />
              );
            } else if (msg.message_type === "VIDEO") {
              return (
                <video className="chat_img" src={msg.multimedia_url} controls />
              );
            } else if (msg.message_type === "LINK") {
              return <p>{msg?.message}</p>;
            } else if (msg.message_type === "AUDIO") {
              return (
                <>
                  {console.log("msg?.message: ", msg.message)}
                  <audio controls>
                    <source src={msg?.multimedia_url} type="audio/webm" />
                    Your browser does not support the audio element.
                  </audio>
                </>
              );
            }
          };

          // console.log('msg.user_id: ', typeof msg.user_id);
          // print msg.
          // eslint-disable-next-line

          if (msg.user_id == localStorage.getItem("user_id")) {
            return (
              <div
                className="msg right-msg"
                key={msg.id}
                // eslint-disable-next-line
                ref={i + 1 == groupChat.length ? lastMessageRef : null}
              >
                <div className="msg-item">
                  <div className="msg-bubble">
                    <div className="msg-text">{checkMsgType()}</div>
                    {/* 
                                                <div className="msg-text">
                                                    <p>{msg?.message}</p>
                                                </div> 
                                            */}
                  </div>
                </div>
                <div className="msg-img">
                  <img
                    src={
                      msg.user?.image_url
                        ? msg.user?.image_url
                        : `https://picsum.photos/100/100`
                    }
                    alt=""
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="msg left-msg"
                key={msg.id}
                // eslint-disable-next-line
                ref={i + 1 == groupChat.length ? lastMessageRef : null}
              >
                <div className="msg-img">
                  <img
                    src={
                      msg.user?.image_url
                        ? msg.user?.image_url
                        : `https://picsum.photos/100/100`
                    }
                    alt=""
                  />
                </div>
                <div className="msg-item">
                  <div className="msg-bubble">
                    <div className="msg-text">
                      <div className="msg-user-name pb-2">
                        {msg.user?.first_name} {msg.user?.last_name}
                      </div>
                      {checkMsgType()}
                      {/* <p>{msg?.message}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        });
      } else {
        return (
          <div className="Loader d-flex justify-content-center">
            <div className="my-5" role="status">
              {/* <span className="sr-only"></span> */}
            </div>
            <div className=" my-5 my-auto">
              {t("iniciar conversación")}. . .
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="Loader d-flex justify-content-center">
          <div className="spinner-border my-5" role="status">
            <span className="sr-only"></span>
          </div>
          <div className=" my-5 my-auto">
            {" "}
            &nbsp;
            {t("Enviando")}...
          </div>
        </div>
      );
    }
  };
  const handleSendMessage = (e) => {
    e.preventDefault();
    const messageHistory = (data) => {
      console.log("message history:- ", data);
      setMessageTyped("");
      setGroupChat(data.messages);
    };
    let uid = userId;
    let msgParameter = {
      receiverId: uid,
      message: messageTyped,
      message_type: "TEXT",
    };
    if (messageTyped?.trim()?.length) {
      socket.emit("send_private_message_moderator", msgParameter);
      socket.on("message_history", messageHistory);
    } else if (mediaURL) {
      if (media.type.includes("image")) {
        msgParameter.message_type = "IMAGE";
      } else if (media.type.includes("video")) {
        msgParameter.message_type = "VIDEO";
      } else if (media.type.includes("audio")) {
        msgParameter.message_type = "AUDIO";
      }
      msgParameter.media_url = mediaURL;
      socket.emit("send_private_message_moderator", msgParameter);
      socket.on("message_history", messageHistory);
      setMedia(null);
      setMediaURL(null);
      setRecordedFile(null);
    }
  };
  const getMediaPreview = () => {
    console.log("media: ", media);
    if (media.type === "audio/webm") {
      return;
    }
    let mType = media.type;
    console.log("mType: ", mType);
    if (mType.includes("image")) {
      return <img src={mediaURL} alt={media.name}></img>;
    } else if (mType.includes("video")) {
      return <video src={mediaURL} width="180px" />;
    }
  };
  function handleclickinput(e) {
    inputRef.current.click();
  }
  const handleMediaSelection = (e) => {
    setIsWait(true);
    setMedia(e.target.files[0]);
    console.log("e.target.files[0]: ", e.target.files[0]);
    if (e.target.files[0]) {
      formData.append("media", e.target.files[0]);
      formData.append("inbox_uid", userDetails?.inbox_details?.inbox_uid);

      fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData,
      })
        .then(function (res) {
          if (res.ok) {
            return res.json();
          }
        })
        .then((resp) => {
          setIsWait(false);
          console.log("media bmc url: ", resp.data);
          setMediaURL(resp.data);
        })
        .catch(function (e) {
          console.log(e);
          setIsWait(false);
          Swal.fire(t("Error, No se pueden compartir medios."));
        });
    } else {
      console.log("seleccione medios!");
    }
  };
  const handlegClick = () => {
    setIsgActive(!isgActive);
  };
  // const handlepClick = () => {
  //     // Toggle the isActive state when the component is clicked
  //     setIspActive(!ispActive);
  // };
  // const handledClick = () => {
  //     // Toggle the isActive state when the component is clicked
  //     setIsdActive(!isdActive);
  // };
  const commentClick = (id) => {
    setSelectedPostId(id);
  };
  const showMemberShip = () => {
    setInfoVis(false);
    setCmtVis(false);
    setThreeOp(true);
  };
  function formatDate(inputDate) {
    if (typeof inputDate !== "string") {
      return "Invalid input";
    }

    const dateParts = inputDate.split(/[-T:.Z]/);
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
    const day = parseInt(dateParts[2]);
    const hour = parseInt(dateParts[3]);
    const minute = parseInt(dateParts[4]);
    const second = parseInt(dateParts[5]);

    const date = new Date(Date.UTC(year, month, day, hour, minute, second));
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );

    return formattedDate;
  }
  async function fetchData() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getUserDetails`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        Swal.fire(t("Caducidad del token, volver a iniciar sesión"));
      }

      const data = await response.json();

      setData(data.data);
      setIsban(data.data.is_blocked);
      setIsVerify(data.data.is_verified);
      setShstrURL(data.data.is_verified);

      console.log("User Details NEW", data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }
  async function verifyUnverify() {
    const message = `${
      isVerify
        ? t("¿Quieres eliminar la verificación del usuario?")
        : t("¿Quieres verificar al usuario?")
    }`;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("Estas seguro"),
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const apiUrl = `${GlobalURL}/api/dev/app_moderator/verifyUser`;
          const token = localStorage.getItem("token");

          try {
            const response = await fetch(apiUrl, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
              },
              body: JSON.stringify({
                user_id: userId,
              }),
            });
            if (response.code === 401) {
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              navigate("/login");
            }

            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            if (response.ok) {
              const data = await response.json();
              setIsVerify(!isVerify);
              setShstrURL(data.data.is_verified);
              console.log("API Response:", data.message);
              swalWithBootstrapButtons.fire({
                title: t("¡Hecho!"),
                text: "",
                icon: "success",
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  }
  async function blockUnblock() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("Estas seguro"),
        text: `${
          isban
            ? t("Quiere eliminar la prohibición del usuario?")
            : t("¿Quieres prohibir al usuario?")
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const apiUrl = `${GlobalURL}/api/dev/app_moderator/changeUserStatus`;
          const token = localStorage.getItem("token");

          try {
            const response = await fetch(apiUrl, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
              },
              body: JSON.stringify({
                user_id: userId,
              }),
            });
            if (response.code === 401) {
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              navigate("/login");
            }

            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            if (response.ok) {
              const data = await response.json();
              console.log("API Response:", data.message);
              setIsban(!isban);
              swalWithBootstrapButtons.fire({
                title: t("¡Hecho!"),
                text: `${
                  isban ? t("Prohibición eliminada") : t("Usuario prohibido")
                }`,
                icon: "success",
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  }
  // const setShowClickedSurl = () => {
  //     setShowStreamingURL(!showStreamingURL);
  // }
  async function miPalco() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getMiBoxAllHomeData`;
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("MiPalco", responseData.data);
        const data = responseData.data;
        // Sort the array based on the createdAt key
        const sortedArray = [...data].sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          // Compare the dates for sorting in descending order
          return dateB - dateA;
        });
        // Update the state with the sorted array
        console.log("Sorted MI palco resp", sortedArray);
        setSortedData(sortedArray);
        // colg mi sortedArray
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        console.log(errorData);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  async function fetchComment(post_id) {
    setCommentreply([]);
    setLoadigComments(true);
    setComments([]);
    const apiUrl = `https://fancy-valued-goat.ngrok-free.app/api/dev/app_moderator/getPostComments`;
    const token = localStorage.getItem("token");
    console.log(post_id, "<-->", userId);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          post_id: post_id,
          currentUserId: userId,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Fetch Comment: ", responseData.data);
        const data = responseData.data;
        console.log("Comments : ", data);
        setComments(data);
        setLoadigComments(false);
      } else {
        setLoadigComments(false);
        Swal.fire(t("Error del servidor al obtener el comentario"));
      }
    } catch (error) {
      setLoadigComments(false);
      console.error("Error:", error.message);
      Swal.fire(t("No se puede cargar la respuesta de comentarios"));
    }
  }
  async function fetchCommentReply(post_id, cmt_id) {
    setCommentreply([]);
    console.log(post_id, "PID<-->CID", cmt_id);
    setSelectedCommentId(cmt_id);
    setLoadigCommentsReply(true);
    const apiUrl = `https://fancy-valued-goat.ngrok-free.app/api/dev/app_moderator/getRepliesOfComments`;
    console.log("USer ID ", userId);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          comment_id: cmt_id,
          post_id: post_id,
          currentUserId: userId,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("Fetch Comment reply ", responseData.data);
        const data = responseData.data;
        console.log("Comments Reply : ", data);
        setCommentreply(data);
        setLoadigCommentsReply(false);
      } else {
        setLoadigCommentsReply(false);
        Swal.fire(t("Error del servidor al obtener el comentario"));
      }
    } catch (error) {
      setLoadigCommentsReply(false);
      Swal.fire(t(t("No se puede cargar la respuesta de comentarios")));
      console.error("Error:", error.message);
    }
  }
  async function membershipDetail() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getUserMembershipDetails`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();
        if (!responseData.data.activeMembership) {
          return;
        }
        setSubscriptionDetails(responseData.data.activeMembership);
        console.log("Subscription", responseData.data);

        responseData.data.activeMembership?.forEach((member) => {
          if (member?.plan_id == 1) {
            setIsGol(true);
          }
          if (member?.plan_id == 2) {
            setIsDia(true);
          }
          if (member?.plan_id == 3) {
            setIsPla(true);
          }
          // if (member.plan_id !== 1 && member.plan_id !== 2 && member.plan_id !== 3) {
          //     setIsGol(false);
          //     setIsDia(false);
          //     setIsPla(false);
          // }
          // Add more cases if needed
        });

        let oroCount = 0;
        let platCount = 0;
        let diamCount = 0;

        responseData.data.activeMembership?.forEach((member) => {
          console.log("member.plan_id: ", member.plan_id);

          if (member.plan_id === 1 || member.plan_id === 2) {
            oroCount++;
          }
          if (member.plan_id === 3 || member.plan_id === 4) {
            platCount++;
          }
          if (member.plan_id === 5 || member.plan_id === 6) {
            diamCount++;
          }
        });

        setOro(oroCount);
        setPlat(platCount);
        setDiam(diamCount);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      throw error; // Rethrow the error for the caller to handle if needed
    }
  }
  async function fetchTree() {
    const requestBody = JSON.stringify({ user_id: userId });
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/getUserInsignia`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: requestBody,
        }
      );
      if (!response.ok) {
        Swal.fire(t("Error del servidor al obtener el comentario"));
      }
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
        return;
      }
      const data = await response.json();
      console.log("Insignia tree ", data.data);
      setInsigniaAvailable((prev) =>
        data.data.map((item) => item.insignium.insignia_id)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
    }
  }
  const addAudioElement = (blob) => {
    const audioFile = new File([blob], "recorded_audio.webm", {
      type: "audio/webm",
    });
    handleMediaSelectionAsync(audioFile)
      .then(() => {
        console.log("Media selection completed successfully");
      })
      .catch((error) => {
        console.error("Error in handling media selection:", error);
      });
  };
  const handleVoiceRecord = () => {
    setRecorder(!recorder);
  };
  const handleMediaSelectionAsync = async (event) => {
    setRecordedFile(null);
    const selectedMedia = event;
    console.log("selectedMedia: ", selectedMedia);
    setMedia(selectedMedia);

    if (selectedMedia) {
      const form = new FormData();
      form.append("media", selectedMedia);
      form.append("inbox_uid", userId);

      try {
        const response = await fetch(
          `${GlobalURL}/api/dev/debate/chat/sendmultimedia`,
          {
            method: "POST",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "ngrok-skip-browser-warning": "69420",
            },
            body: form,
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("media URL: ", responseData.data);
          setRecordedFile(responseData.data);
          setMediaURL(responseData.data);
        } else {
          console.error("Failed to upload media.");
          Swal.fire(t("Error, No se pueden compartir medios."));
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire(t("Error, No se pueden compartir medios."));
      }
    } else {
      console.log("Select media!");
    }
  };
  useEffect(() => {
    fetchData();
    miPalco();
    membershipDetail();
    fetchTree();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="digital-manager-page">
            <Container fluid>
              <Row>
                <Col className="first-sec" xs xl="3">
                  <div className="membersias-sec">
                    <div
                      className="gems"
                      onClick={() => {
                        navigate("/gemshistory", {
                          state: { userId: userId, gems: data.gems_count },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="./img/Gemas.png" alt="gem" />
                      {data.gems_count} {t("Gemas")}
                    </div>
                    <div className="available-membership">
                      <h6>{t("membresías disponibles")}</h6>
                      <ul className="available-body">
                        <li className={isGol ? "" : "disable"}>
                          <span>
                            <img src="./img/gold-bar.png" alt="" />
                            {t("Oro")}
                          </span>
                          <span>x{oro}</span>
                        </li>
                        <li className={isPla ? "" : "disable"}>
                          <span>
                            <img src="./img/green-bar.png" alt="" />
                            {t("Platino")}
                          </span>
                          <span>x{plat}</span>
                        </li>
                        <li className={isDia ? "" : "disable"}>
                          <span>
                            <img src="./img/diamand.png" alt="" />
                            {t("Diamante")}
                          </span>
                          <span>x{diam}</span>
                        </li>
                      </ul>
                      <div className="two-buttons-sec">
                        <button
                          className={`button-view-member ${
                            viewMembership ? "active" : ""
                          }`}
                          onClick={() => {
                            showMemberShip();
                          }}
                        >
                          {viewMembership
                            ? t("OCULTAR MEMBRESÍAS")
                            : t("VER MEMBRESÍAS")}
                        </button>
                        <div>
                          {/* {shstrURL && (<button className='button-verified-member' onClick={() => { setShowClickedSurl() }}>STREAMING URL</button>)} */}
                          {/* {showStreamingURL && (<div className='show-div-on-click'> </div>)} */}
                        </div>
                        <button
                          className="button-send-message"
                          onClick={() => {
                            connectSocket();
                            setShowChat(!showChat);
                          }}
                        >
                          {t("ENVIAR MENSAJE")}
                        </button>
                      </div>
                      <div
                        className="chatPage-sec chatBox-toggle"
                        style={{ display: showChat ? "inherit" : "none" }}
                      >
                        <section className="msger">
                          <main className="msger-chat added-msger-chat">
                            {renderChat()}
                          </main>

                          <div className="input-form">
                            <div className="input-form-wrap">
                              <form
                                className="msger-inputarea"
                                onSubmit={handleSendMessage}
                              >
                                <div
                                  className="msger-attachment-btn left-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="file"
                                    // className="msger-attachment-btn left-btn"
                                    accept="image/*, video/*, audio/*, .pdf"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                    onChange={handleMediaSelection}
                                    // multiple
                                  />
                                  <FiPlus onClick={handleclickinput} />
                                </div>
                                {
                                  <>
                                    {isWait && (
                                      <div className="previewMedia my-2">
                                        {t("Cargando medios")}...
                                      </div>
                                    )}
                                    {mediaURL && recordedFile && (
                                      <div className="previewMedia">
                                        {getMediaPreview()}
                                      </div>
                                    )}

                                    {recordedFile && (
                                      <div className="previewMedia other-audio">
                                        {/* <p>Recorded Audio:</p> */}
                                        <audio controls>
                                          <source
                                            src={recordedFile}
                                            type="audio/webm"
                                          />
                                          {t(
                                            "Su navegador no admite elElemento de audio."
                                          )}
                                        </audio>
                                      </div>
                                    )}
                                  </>
                                }
                                <input
                                  type="text"
                                  className="msger-input"
                                  value={messageTyped}
                                  onChange={(e) => {
                                    setMessageTyped(e.target.value);
                                  }}
                                  placeholder={t("Escribe un mensaje")}
                                />

                                <button
                                  className="msger-attachment-btn right-btn"
                                  type="submit"
                                  style={{
                                    color: "white",
                                    transform: "rotate(328deg)",
                                    marginRight: "40px",
                                  }}
                                >
                                  {/* <img src="../../img/rightArr.png" alt="" /> */}
                                  <PiTriangle />
                                </button>
                                <div
                                  onClick={() => {
                                    handleVoiceRecord();
                                  }}
                                  className={`voice-recorder`}
                                >
                                  <div>
                                    <AudioRecorder
                                      onRecordingComplete={addAudioElement}
                                      audioTrackConstraints={{
                                        noiseSuppression: true,
                                        echoCancellation: true,
                                      }}
                                      onNotAllowedOrFound={(err) =>
                                        console.table(err)
                                      }
                                      downloadOnSavePress={false} // Disable automatic download
                                      downloadFileExtension="webm"
                                      mediaRecorderOptions={{
                                        audioBitsPerSecond: 128000,
                                      }}
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mid-sec" xl="6">
                  <div className="news-header">
                    <div
                      className="back-sec"
                      alt=""
                      onClick={() => navigate(-1)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 className="main-title">{t("DIRIGENTE DIGITAL")}</h3>
                    <div className="search-icon">
                      <FiSearch style={{ opacity: "0" }} />
                    </div>
                  </div>
                  <div className="digital-leader">
                    <div className="fol-num-mipoly">
                      <div className="fol-fol-num">
                        <h6>{t("Seguidores")}</h6>
                        <h6>{data.follower_count}</h6>
                      </div>
                      <div
                        className="mi-poly"
                        onClick={() => {
                          setInfoVis(!infoVis);
                          setCmtVis(!cmtVis);
                          setThreeOp(false);
                          if (infoVis === cmtVis) {
                            setInfoVis(true);
                          }
                        }}
                      >
                        <div className="img-mi-palco">
                          <img src="./img/Palco Icon.svg" alt="" />
                        </div>
                        <div className="mi-poly-statement">{t("MI PALCO")}</div>
                      </div>
                    </div>

                    <div className="center-dl">
                      <div className="center-head">
                        <img src={data?.image_url} alt="" />
                        {isVerify && (
                          <div className="img-ver-dia">
                            {" "}
                            <img src="./img/Diamante.png" alt="" />{" "}
                          </div>
                        )}
                        {isban && (
                          <div
                            className={`img-ban-dia ${
                              isVerify ? "" : "unveri"
                            }`}
                          >
                            {" "}
                            <img src="./img/banlogo.png" alt="" />{" "}
                          </div>
                        )}
                        <h3>
                          {data.first_name} {data.last_name}
                        </h3>
                      </div>

                      {infoVis && (
                        <div className="personal-info">
                          <h4>{t("Información Personal")}</h4>
                          <div className="personal-data">
                            <span>{t("Nombre de usuario")}</span>
                            <span>{data.username}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Nombre")}</span>
                            <span>{data.first_name}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Apellido")}</span>
                            <span>{data.last_name}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Fecha de nacimiento")}</span>
                            <span>{data.DOB}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Género")}</span>
                            <span>{data.gender}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("País")}</span>
                            <span>{data.country}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Correo Electrónico")}</span>
                            <span>{data.email}</span>
                          </div>
                          <div className="personal-data">
                            <span>{t("Número de teléfono")}</span>
                            <span>{data.mobile}</span>
                          </div>
                        </div>
                      )}
                      {threeOp && (
                        <div
                          style={{ maxHeight: "772px", overflowY: "scroll" }}
                          class="personal-info"
                        >
                          <h4>{t("MEMBRESÍAS DISPONIBLE")}</h4>

                          {subscriptionDetails.map((items, index) => {
                            if (
                              items.membership_plans_new.plan_category ===
                              "gold"
                            ) {
                              return (
                                <>
                                  <div key={index}>
                                    <div
                                      className={`acc-head-outer ${
                                        selGold === index ? "active" : ""
                                      }`}
                                    >
                                      <div
                                        className="acc-head"
                                        onClick={() => {
                                          setSelGold("");
                                        }}
                                      >
                                        <div className="country-name">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            {items.team_name}
                                          </p>
                                        </div>
                                        <div className="country-assets">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            {t("ORO")}
                                          </p>
                                          <img
                                            src="./img/gold-bar.png"
                                            alt=""
                                          />
                                        </div>
                                      </div>

                                      <div className="first-list">
                                        <div className="club-img">
                                          <img src={items.team_shield} alt="" />
                                        </div>
                                        <div className="club-centerText2">
                                          <div className="cardInfo">
                                            <img
                                              src="./img/cardImg.png"
                                              alt=""
                                            />
                                            <span>
                                              {" "}
                                              <IoCardOutline />{" "}
                                              <strong>
                                                {" "}
                                                US${" "}
                                                {
                                                  items.membership_plans_new
                                                    .price
                                                }{" "}
                                              </strong>
                                              {t("por")}{" "}
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? "Mensual"
                                                : "Anual"}
                                            </span>
                                          </div>
                                          <div className="cardDate">
                                            <img
                                              src="./img/cardDate.png"
                                              alt=""
                                            />
                                            <span>
                                              {" "}
                                              <BsCalendar3 /> {t(
                                                "Renovación"
                                              )}{" "}
                                              {formatDat2(items.expire_date)}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="club-planes">
                                          <div
                                            onClick={() => {
                                              setSelGold(index);
                                            }}
                                          >
                                            {" "}
                                            {t("ver planes")} &gt;
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="acc-body">
                                      <ul className="club-list">
                                        <li className="text-center d-block">
                                          <div className="diamond-member mb-1">
                                            <span>
                                              {" "}
                                              <strong>
                                                {" "}
                                                {t("Membresia ORO")}{" "}
                                              </strong>{" "}
                                            </span>
                                          </div>
                                          <div className="membership-price">
                                            <span>
                                              US$S{" "}
                                              {items.membership_plans_new.price}
                                              &nbsp;
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? "Mensual"
                                                : "Anual"}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                            if (
                              items.membership_plans_new.plan_category ===
                              "diamond"
                            ) {
                              return (
                                <>
                                  <div>
                                    <div
                                      className={`acc-head-outer diamond-acc-head ${
                                        selDiam === index ? "active" : ""
                                      }`}
                                    >
                                      <div
                                        className="acc-head"
                                        onClick={() => {
                                          setSelDiam("");
                                        }}
                                      >
                                        <div className="country-name">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            {items.team_name}
                                          </p>
                                        </div>
                                        <div className="country-assets">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            DIAMOND
                                          </p>
                                          <img
                                            src="./img/diamante ICO.png"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="first-list">
                                        <div className="club-img">
                                          <img src={items.team_shield} alt="" />
                                        </div>
                                        <div className="club-centerTextD">
                                          <div className="cardInfo">
                                            <img
                                              src="./img/cardImg.png"
                                              alt=""
                                            />
                                            <span>
                                              <IoCardOutline />{" "}
                                              <strong>
                                                {" "}
                                                US${" "}
                                                {
                                                  items.membership_plans_new
                                                    .price
                                                }{" "}
                                              </strong>
                                              {t("por")}{" "}
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? "Mensual"
                                                : "Anual"}
                                            </span>
                                          </div>
                                          <div className="cardDate">
                                            <img
                                              src="./img/cardDate.png"
                                              alt=""
                                            />
                                            <span>
                                              <BsCalendar3 /> Renovación{" "}
                                              {formatDat2(items.expire_date)}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="club-planes">
                                          <div
                                            onClick={() => {
                                              setSelDiam(index);
                                            }}
                                          >
                                            {" "}
                                            {t("ver planes")} &gt;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="acc-body">
                                      <ul className="club-list">
                                        <li className="text-center d-block">
                                          <div className="diamond-member mb-1">
                                            <span>
                                              {" "}
                                              <strong>
                                                {" "}
                                                Membresia Diamante{" "}
                                              </strong>{" "}
                                            </span>
                                          </div>
                                          <div className="membership-price">
                                            <span>
                                              US$S{" "}
                                              {items.membership_plans_new.price}
                                              &nbsp;
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? t("Mensual")
                                                : t("Anual")}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                            if (
                              items.membership_plans_new.plan_category ===
                              "platinum"
                            ) {
                              return (
                                <>
                                  <div>
                                    <div
                                      className={`acc-head-outer ${
                                        selPlat === index ? "active" : ""
                                      }`}
                                    >
                                      <div
                                        onClick={() => {
                                          setSelPlat("");
                                        }}
                                        style={{
                                          backgroundImage:
                                            "linear-gradient(#0D873F, #27EB77)",
                                        }}
                                        className="acc-head"
                                      >
                                        <div className="country-name">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            {items.team_name}
                                          </p>
                                        </div>
                                        <div className="country-assets">
                                          <p
                                            style={{
                                              fontFamily: "Francois One",
                                            }}
                                          >
                                            Platinum
                                          </p>
                                          <img
                                            src="./img/platino ICO.png"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="first-list">
                                        <div className="club-img">
                                          <img src={items.team_shield} alt="" />
                                        </div>
                                        <div className="club-centerTextG">
                                          <div className="cardInfo">
                                            <img
                                              src="./img/cardImg.png"
                                              alt=""
                                            />
                                            <span>
                                              <IoCardOutline />
                                              <strong>
                                                {" "}
                                                US${" "}
                                                {
                                                  items.membership_plans_new
                                                    .price
                                                }{" "}
                                              </strong>
                                              {t("por")}{" "}
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? t("Mensual")
                                                : t("Anual")}
                                            </span>
                                          </div>
                                          <div className="cardDate">
                                            <img
                                              src="./img/cardDate.png"
                                              alt=""
                                            />
                                            <span>
                                              {" "}
                                              <BsCalendar3 /> Renovación{" "}
                                              {formatDat2(items.expire_date)}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="club-planes">
                                          <div
                                            onClick={() => {
                                              setSelPlat(index);
                                            }}
                                          >
                                            {" "}
                                            {t("ver planes")} &gt;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="acc-body">
                                      <ul className="club-list">
                                        <li className="text-center d-block">
                                          <div className="diamond-member mb-1">
                                            <span>
                                              {" "}
                                              <strong>
                                                {" "}
                                                {t("Membresia Platino")}{" "}
                                              </strong>{" "}
                                            </span>
                                          </div>
                                          <div className="membership-price">
                                            <span>
                                              US$S{" "}
                                              {items.membership_plans_new.price}
                                              &nbsp;
                                              {items.membership_plans_new
                                                .duration === "monthly"
                                                ? t("Mensual")
                                                : t("Anual")}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                            return null; // Handle other cases if needed
                          })}
                        </div>
                      )}

                      {!infoVis && cmtVis && (
                        <div
                          className="support div"
                          style={{ height: "635px" }}
                        ></div>
                      )}
                      {cmtVis && (
                        <div class="personal-info-pol">
                          <h4>{t("MI PALCO")}</h4>
                          {sortedData.map((item) => {
                            switch (item.type) {
                              case "event":
                                return (
                                  <div
                                    key={item.event_id}
                                    className="comment-box1 black-bg"
                                  >
                                    <div className="reciver-left">
                                      <img src="./img/newsimg.png" alt="" />
                                    </div>
                                    <div className="profile-text">
                                      <span className="profile-name">
                                        <strong>{item.title}</strong>
                                      </span>
                                      <span
                                        className="profile-desc"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      />
                                    </div>
                                    <div className="profile-time">
                                      <span>{formatDate(item.event_date)}</span>
                                    </div>
                                  </div>
                                );
                              case "post":
                                return (
                                  <>
                                    <div
                                      key={item.post_id}
                                      className="post-sec-border-details"
                                    >
                                      <div className="comment-box1">
                                        <div className="reciver-left">
                                          <img
                                            src={item.user?.image_url}
                                            alt=""
                                          />
                                          {item.user.is_verified && (
                                            <img
                                              src="./img/Diamante.png"
                                              className="gem-profile-mi-palco"
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        <div className="profile-text">
                                          <span className="profile-name">
                                            <strong>
                                              {" "}
                                              {item.user.first_name}
                                            </strong>{" "}
                                            <strong className="username-mi-palco">
                                              @{item.user.username}
                                            </strong>
                                          </span>
                                          <span className="profile-desc">
                                            {" "}
                                            {item.description}{" "}
                                          </span>
                                          {item.post_media.length > 0 && (
                                            <div>
                                              <span className="profile-desc">
                                                {/* Your existing content */}
                                              </span>
                                              {/* Map over post_media and render elements */}
                                              <div className="postmediaDiv">
                                                {item.post_media.map(
                                                  (media, index) => (
                                                    <div key={index}>
                                                      {media.type ===
                                                        "image" && (
                                                        <img
                                                          src={media.url}
                                                          className="postMediaImage"
                                                          alt={`Media ${index}`}
                                                        />
                                                      )}
                                                      {media.type ===
                                                        "video" && (
                                                        <video
                                                          controls
                                                          className="postMediaVideo"
                                                          key={index}
                                                          autoPlay={true}
                                                        >
                                                          <source
                                                            src={media.url}
                                                            type="video/mp4"
                                                          />
                                                          {t(
                                                            "Su navegador no soporta la etiqueta de vídeo."
                                                          )}
                                                        </video>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className="profile-time">
                                          {/* <Link>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                      <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                      <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  </svg>
                                              </Link> */}
                                        </div>
                                      </div>
                                      <div className="comment-sec">
                                        <ul className="social-comment">
                                          <li
                                            onClick={() => {
                                              if (item.comment_count > 0) {
                                                console.log(item.post_id);
                                                setSelectedPostId(item.post_id);
                                                fetchComment(item.post_id);
                                              }
                                            }}
                                          >
                                            <GoComment
                                              style={{ cursor: "pointer" }}
                                            />
                                            <span>{item.comment_count}</span>
                                          </li>
                                          <li>
                                            <AiOutlineLike />
                                            <span>{item.likes_count}</span>
                                          </li>
                                          <li>
                                            <GoShare />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    {selectedPostId === item.post_id &&
                                      (loadigComments ? (
                                        <div
                                          className=""
                                          style={{
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="loading-adjust">
                                            <div className="Loader d-flex justify-content-center align-items-center">
                                              <div
                                                className="spinner-border spinner-border-sm my-5"
                                                role="status"
                                              >
                                                <span className="sr-only"></span>
                                              </div>
                                              <div className="my-5">
                                                {" "}
                                                &nbsp; {t("Cargando...")}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="comment-box-outer black-bg transparent-bg cm-box">
                                          {comments.map((thing, index) => (
                                            <>
                                              <div
                                                className="comment-box"
                                                key={index}
                                              >
                                                <div className="reciver-left">
                                                  <img
                                                    src={thing.user?.image_url}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="profile-text">
                                                  <span className="profile-name">
                                                    <strong>
                                                      {thing.user.first_name}{" "}
                                                    </strong>
                                                    @{thing.user.username}
                                                  </span>
                                                  <span className="profile-desc">
                                                    {thing.content}
                                                  </span>
                                                  {/* <span className='profile-desc'>{unit.comment_data.data}</span> */}
                                                </div>
                                                <div className="profile-time">
                                                  {/* <Link>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='none'>
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </Link> */}
                                                </div>
                                                <div className="comment-box-outer black-bg transparent-bg cm-box ">
                                                  <div className="comment-sec">
                                                    <ul className="social-comment">
                                                      <ul className="social-comment">
                                                        <li
                                                          onClick={() => {
                                                            thing.reply_count &&
                                                              fetchCommentReply(
                                                                item.post_id,
                                                                thing.post_comment_id
                                                              );
                                                          }}
                                                        >
                                                          <GoComment
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          />
                                                          <span>
                                                            {thing.reply_count}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <AiOutlineLike />
                                                          <span>
                                                            {thing.likes_count}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <GoShare />
                                                        </li>
                                                      </ul>
                                                    </ul>
                                                  </div>
                                                  {selectedCommentId ===
                                                    thing.post_comment_id &&
                                                    (loadigCommentsReply ? (
                                                      <div
                                                        className=""
                                                        style={{
                                                          height: "25px",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <div className="loading-adjust">
                                                          <div className="Loader d-flex justify-content-center align-items-center">
                                                            <div
                                                              className="spinner-border spinner-border-sm my-5"
                                                              role="status"
                                                            >
                                                              <span className="sr-only"></span>
                                                            </div>
                                                            <div className="my-5">
                                                              {" "}
                                                              &nbsp;{" "}
                                                              {t("Cargando...")}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="comment-box-outer black-bg transparent-bg cm-box">
                                                        {commentreply.map(
                                                          (things, index) => (
                                                            <>
                                                              <div
                                                                className="comment-box"
                                                                key={index}
                                                              >
                                                                {console.log(
                                                                  "selectedCommentId === things.comment_id: ",
                                                                  selectedCommentId,
                                                                  things.comment_id
                                                                )}
                                                                <div className="reciver-left">
                                                                  <img
                                                                    src={
                                                                      things
                                                                        ?.user
                                                                        ?.image_url
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </div>
                                                                <div className="profile-text">
                                                                  <span className="profile-name">
                                                                    <strong>
                                                                      {
                                                                        things
                                                                          .user
                                                                          .first_name
                                                                      }{" "}
                                                                    </strong>
                                                                    @
                                                                    {
                                                                      things
                                                                        .user
                                                                        .username
                                                                    }
                                                                  </span>
                                                                  <span className="profile-desc">
                                                                    {
                                                                      things.content
                                                                    }
                                                                  </span>
                                                                  {/* <span className='profile-desc'>{unit.comment_data.data}</span> */}
                                                                </div>
                                                                <div className="profile-time">
                                                                  {/* <Link>
                                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='none'>
                                                                              <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                              <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                              <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#000000ad" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                          </svg>
                                                                      </Link> */}
                                                                </div>
                                                                <div className="comment-box-outer black-bg transparent-bg cm-box ">
                                                                  <div className="comment-sec">
                                                                    <ul className="social-comment">
                                                                      <ul className="social-comment">
                                                                        <li
                                                                          onClick={() => {}}
                                                                        >
                                                                          <GoComment
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          />
                                                                          <span>
                                                                            {
                                                                              things.comment_count
                                                                            }
                                                                          </span>
                                                                        </li>
                                                                        <li>
                                                                          <AiOutlineLike />
                                                                          <span>
                                                                            {
                                                                              things.likes_count
                                                                            }
                                                                          </span>
                                                                        </li>
                                                                        <li>
                                                                          <GoShare />
                                                                        </li>
                                                                      </ul>
                                                                    </ul>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )
                                                        )}
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      ))}
                                  </>
                                );
                              case "proposal":
                                return (
                                  <div
                                    key={item.proposal_id}
                                    className="comment-box-outer black-bg token-box "
                                  >
                                    <div className="d-flex justify-content-between tb-top-sec">
                                      <div className="green-text">
                                        <p>{t("creado")}</p>
                                        <p>{formatDate(item.createdAt)}</p>
                                      </div>
                                      <div className="star">
                                        <img src="./img/star.svg" alt="" />
                                      </div>
                                      <div className="red-text">
                                        <p>{t("vence")}</p>
                                        <p>{formatDate(item.expire_time)}</p>
                                      </div>
                                    </div>
                                    <div className="tb-middle-sec">
                                      <div className="white-box">
                                        <p className="mb-0">{item.title}</p>
                                        <div className="dt-logo">
                                          <img src="./img/bg-4.png" alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tb-bottom-sec">
                                      <div className="left-btn">
                                        <button className="red-btn">
                                          {t("Resultados")}
                                        </button>
                                      </div>
                                      <div className="right-btn">
                                        {item.has_voted && (
                                          <div className="green-clr-mi-palco">
                                            {t("Has votado")}{" "}
                                            <img
                                              src="./img/check_2x-removebg-preview.png"
                                              className="tick-button"
                                              alt="img"
                                            />{" "}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              case "debate":
                                return (
                                  <div
                                    key={item.event_id}
                                    className="comment-box black-bg"
                                  >
                                    <div className="debate-logo-mipalco">
                                      <img src="./img/bg-4.png" alt="" />
                                    </div>
                                    <div className="profile-text">
                                      <span className="profile-name">
                                        <strong>{item.title}</strong>
                                      </span>
                                      <span className="profile-desc">
                                        {formatDate(item.updatedAt)}
                                      </span>
                                    </div>
                                    <div className="profile-time">
                                      <span>{formatDate(item.event_date)}</span>
                                    </div>
                                  </div>
                                );
                              // Add more cases as needed
                              default:
                                return (
                                  <div key={item.id}>
                                    {t(
                                      "Contenido predeterminado para tipo desconocido"
                                    )}
                                  </div>
                                );
                            }
                          })}
                        </div>
                      )}
                    </div>
                    <div className="fol-fol-num-2">
                      <h6>{t("Seguidos")}</h6>
                      <h6>{data.following_count}</h6>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" xs xl="3">
                  <div class="available-badge">
                    <h4>{t("Insignias Disponibles")}</h4>
                    <div class="row first-row">
                      <div class="col-12 pr-hier">
                        <div
                          className={
                            insigniaAvailable.find((id) => id === 1)
                              ? ""
                              : "op-31"
                          }
                        >
                          {" "}
                          <span>
                            <img src="./img/i1.svg" alt="" />
                          </span>{" "}
                        </div>
                        {!insigniaAvailable.find((id) => id === 1) && (
                          <span className="lock-icon-first">
                            <img
                              className="fist-lock"
                              src="./img/candado.svg"
                              alt="lockIcon"
                            />
                          </span>
                        )}

                        <h6>{t("Director Deportivo")}</h6>
                        <span className="lock-icon">
                          {/* <img src="./img/candado.svg" alt="lockIcon" /> */}
                        </span>
                      </div>
                    </div>
                    <div class="row sec-row">
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 2)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i2.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 2) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("gerente de obras")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 3)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i3.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 3) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("representante internacional")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 4)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i4.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 4) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("director técnico")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 5)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i5.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 5) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>

                        <h6>{t("secretario de estadio")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 6)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i6.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 6) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>

                        <h6>{t("gerente de marketing")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 7)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i7.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 7) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("secretario técnico")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 8)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i8.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 8) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("secretario de polideportivo")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 9)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i9.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 9) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("relaciones públicas")}</h6>
                      </div>
                      <div class="col-4 dis-fade">
                        <span className="lock-icon-wrapper">
                          <span
                            className={
                              insigniaAvailable.find((id) => id === 10)
                                ? ""
                                : "op-31"
                            }
                          >
                            <img src="./img/i10.svg" alt="" />
                          </span>
                          {!insigniaAvailable.find((id) => id === 10) && (
                            <span className="lock-icon">
                              <img src="./img/candado.svg" alt="lockIcon" />
                            </span>
                          )}
                        </span>
                        <h6>{t("analista táctico")}</h6>
                      </div>
                    </div>
                    <button
                      className="red-btn mt-100"
                      onClick={() => {
                        blockUnblock();
                      }}
                    >
                      {t("Bloquear")} / {t("Desbloquear")}
                    </button>
                    <button
                      className="blue-btn mt-100"
                      onClick={() => {
                        verifyUnverify();
                      }}
                    >
                      {t("VERIFICAR USUARIO")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalManager;
