import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import translations from "../../../locals/translation";
import GlobalURL from "../../BaseUrl";
import Swal from "sweetalert2";
import "./addproduct.css";
import { useEffect } from "react";
import AccessibleTable from "./variantTable";
import { MdDeleteOutline } from "react-icons/md";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";

function Addproduct() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const language = useSelector((state) => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  // Main Product Details
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "", //quater
    baseCurrency: "MXN$", // qauter
    brand: "", // half
    category: "", // half
    gender: "", // quater
    seller_name: "", // half
  });

  // Variants data
  const [row, setRow] = useState([]);
  const [dataHold, setDataHold] = useState({
    Size: "",
    Color: "",
    Quantity: null,
    Action: <div className=""></div>,
  });
  const resetDataHold = () => {
    setDataHold({
      id: null,
      Size: "",
      Color: "",
      Quantity: 0,
      Action: null,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataHold((prev) => ({ ...prev, [name]: value }));
  };
  const handleRowAddData = (e) => {
    e.preventDefault();
    setRow((prev) => {
      // Check if a row with the same size already exists
      const existingRowSize = prev.find((item) => item.Size === dataHold.Size);
      const existingRowColor = prev.find(
        (item) => item.Color.toLowerCase() === dataHold.Color.toLowerCase()
      );
      if (existingRowSize && existingRowColor) {
        // Update the quantity of the existing row
        return prev.map((item) =>
          item.Size === dataHold.Size
            ? {
                ...item,
                Quantity: Number(item.Quantity) + Number(dataHold.Quantity),
              }
            : item
        );
      }

      // If no matching row, check if editing an existing row by ID
      if (dataHold.id) {
        return prev.map((item) =>
          item.id === dataHold.id
            ? { ...dataHold, Action: createEditButton(dataHold.id) }
            : item
        );
      }

      // Add a new row
      return [
        ...prev,
        {
          ...dataHold,
          id: Date.now(),
          Action: createEditButton(Date.now()),
        },
      ];
    });

    // Reset the dataHold object after processing
    resetDataHold();
  };

  const handleEdit = (id) => {
    console.log("id of selected: ", id);
    const entryToEdit = row.find((item) => item.id === id);
    setDataHold(entryToEdit);
  };
  const handleDelete = (id) => {
    setRow((prev) => prev.filter((item) => item.id !== id));
    resetDataHold();
  };
  const createEditButton = (id) => (
    <div className="action-table-buttons">
      <button onClick={() => handleEdit(id)}>
        <BorderColorIcon />
      </button>
      <button onClick={() => handleDelete(id)}>
        <DeleteIcon />
      </button>
    </div>
  );

  //Category Stuff
  const [searchCategory, setSearchCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [originalcategories, setOriginalcategories] = useState([]);

  //stpepper
  const [stepper, setStepper] = useState({ step1: true, step2: false });

  //Category functions
  const getCategory = async () => {
    try {
      const resp = await fetch(
        `${GlobalURL}/api/dev/store/getAllProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await resp.json();
      console.log("Category", data);
      // const calcetinesCategory = data.find(category => category.category_name === "category_name");
      setCategories(data.data);
      setOriginalcategories(data.data);
      if (resp.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
      }
    } catch (error) {
      console.log("error al obtener categoría", error);
    }
  };
  const handleSearchCategory = (term) => {
    if (term == "") {
      setCategories(originalcategories);
      setSearchCategory(term);
      return;
    }
    setSearchCategory(term);
    const filteredCategories = originalcategories.filter((category) =>
      category.category_name.toLowerCase().includes(term.toLowerCase())
    );
    setCategories(filteredCategories);
  };
  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value; // Get the selected category ID
    console.log("selectedCategoryId: ", selectedCategoryId);
    setSelectedCategory(selectedCategoryId);

    // Find the selected category from the categories array
    const selectedCategory = categories.find(
      (category) => category.category_id === selectedCategoryId
    );

    // Update setProductData with the selected category ID
    setProductData((prevData) => ({
      ...prevData,
      category: selectedCategoryId,
    }));
  };
  const handleGenderChange = (event) => {
    const { name, value } = event.target;
    setProductData((prevProductData) => ({
      ...prevProductData,
      [name]: value,
    }));
  };

  // Image Stuff
  const [imageObject, setImageObject] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(false);

  //Image Operation
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setImageObject((prev) =>
      prev.map((item) =>
        item.color === selectedColor
          ? { ...item, images: [...item.images, ...files] }
          : item
      )
    );
  };
  const handleImageDelete = (imgIndex) => {
    setImageObject((prev) =>
      prev.map((item) =>
        item.color === selectedColor
          ? {
              ...item,
              images: item.images.filter((_, index) => index !== imgIndex),
            }
          : item
      )
    );
  };

  //URL
  const url = `${GlobalURL}/api/dev/store/addProduct2`; // Replace with your API endpoint
  // const url = `https://fancy-valued-goat.ngrok-free.app/api/dev/store/addProduct2`; // Replace with your API endpoint

  // sumit Product
  const handleSubmitProduct = async () => {
    setLoading(true);
    const formData = new FormData();
    const size = [];
    const quantities = [];
    const colors = [];
    formData.append("name", productData.name);
    formData.append("description", productData.description);
    formData.append("price", productData.price);
    formData.append("baseCurrency", productData.baseCurrency);
    formData.append("brand", productData.brand);
    formData.append("category", productData.category);
    formData.append("gender", productData.gender);
    formData.append("seller_name", productData.seller_name);
    row?.map((item, index) => {
      formData.append(`sizes[${index}]`, item.Size);
      formData.append(`quantities[${index}][size]`, item.Size);
      formData.append(`quantities[${index}][color]`, item.Color);
      formData.append(`quantities[${index}][quantity]`, item.Quantity);
    });
    imageObject?.map((item, index) => {
      colors.push({ name: item?.color, images: item.images });
      formData.append(`colors[${index}][name]`, item.color);
      item.images.forEach((image, imageIndex) => {
        formData.append(`colors[${index}][images][${imageIndex}]`, image);
      });
    });
    const data = Object.fromEntries(formData);
    console.log("data: Formdata", data);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData,
      });

      if (!response.ok) {
        // Handle non-successful response here
        //   setUploading(false);
        Swal.fire(t("Error del Servidor"));
      }
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        //   setUploading(false);
        Swal.fire(t("Producto agregado exitosamente"));
        setLoading(false);
        navigate("/store");
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      //   setUploading(false);
      Swal.fire(t("Error del Servidor"));
      setLoading(false);
      console.error("Error:", error.message);
    }
  };
  function handleclickinput(e) {
    inputRef.current.click();
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      <div>
        <div className="dashboard">
          <div className="dashboard-container">
            <div className="editExp-page">
              <Container fluid>
                <Row>
                  <Col xs lg="3"></Col>
                  <Col xs lg="6">
                    <div className="exp-editPage">
                      <div className="news-header">
                        <div className="back-sec">
                          <img
                            src="./img/volver.png"
                            alt=""
                            onClick={() => {
                              navigate("/store");
                            }}
                          />
                        </div>
                        <h3
                          className="header-title"
                          style={{
                            fontFamily: "Francois One",
                            fontSize: "23px",
                          }}
                        >
                          {t("Agregar producto")}
                        </h3>
                        <div className="search-icon">
                          <FiSearch />
                        </div>
                      </div>
                      <div className="common-input-forms newsAddContent">
                        <div encType="multipart/form-data">
                          <div className="common-form-control img-video-form-control">
                            <label>{t("Nombre del vendedor")}</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                className="f-input"
                                placeholder={t("Insertar nombre del vendedor")}
                                value={productData.seller_name}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setProductData((prevData) => ({
                                    ...prevData,
                                    seller_name: newName,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="common-form-control img-video-form-control">
                            <label>{t("nombre del producto")}</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                className="f-input"
                                placeholder={t("Insertar nombre del producto")}
                                value={productData.name}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setProductData((prevData) => ({
                                    ...prevData,
                                    name: newName,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="common-form-control img-video-form-control">
                            <label>{t("Descripción")}</label>
                            <div className="file-input-sec-text">
                              <textarea
                                id="uploadFile"
                                className="f-text"
                                placeholder={t(
                                  "Insertar descripción del producto"
                                )}
                                value={productData.description}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setProductData((prevData) => ({
                                    ...prevData,
                                    description: newName,
                                  }));
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="four-item-goup">
                            <div className="common-form-quater-control img-video-form-control">
                              <label>{t("Precio")} $</label>
                              <div className="file-input-sec">
                                <input
                                  id="uploadFile"
                                  className="input-quater"
                                  placeholder={t("Insertar precio")}
                                  style={{ background: "transparent" }}
                                  value={productData.price}
                                  onChange={(e) => {
                                    const newName = e.target.value;
                                    setProductData((prevData) => ({
                                      ...prevData,
                                      price: newName,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="common-form-half-control img-video-form-control">
                              <label>{t("Marca")}</label>
                              <div className="file-input-sec">
                                <input
                                  id="uploadFile"
                                  style={{ background: "transparent" }}
                                  className="input-half"
                                  placeholder={t("Insertar nombre de marca")}
                                  value={productData.brand}
                                  onChange={(e) => {
                                    const newName = e.target.value;
                                    setProductData((prevData) => ({
                                      ...prevData,
                                      brand: newName,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="common-form-half-control img-video-form-control">
                              <label>{t("Base Currency")}</label>
                              <div className="file-input-sec">
                                <select
                                  className="select-base-currency"
                                  onChange={(e) => {
                                    const newName = e.target.value;
                                    setProductData((prevData) => ({
                                      ...prevData,
                                      baseCurrency: newName,
                                    }));
                                  }}
                                >
                                  <option value={"MXN$"}>
                                    MXN - Mexican Peso
                                  </option>
                                  <option value={"ARS$"}>
                                    ARS - Argentine Peso
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="common-form-quater-control img-video-form-control">
                              <label>{t("Categoría de búsqueda")}</label>
                              <div className="file-input-sec">
                                <input
                                  id="uploadFile"
                                  style={{ background: "transparent" }}
                                  className="input-half"
                                  placeholder={t("Término de búsqueda")}
                                  value={searchCategory}
                                  onChange={(e) => {
                                    const term = e.target.value;
                                    handleSearchCategory(term);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="category-Grid">
                            {categories.map((category) => (
                              <div
                                key={category.category_id}
                                className={`options ${
                                  selectedCategory == category.category_id &&
                                  "opt-background"
                                }`}
                              >
                                <div className="input-label">
                                  <input
                                    type="radio"
                                    id={`category_${category.category_id}`} // Unique ID
                                    name="category" // Group radio buttons
                                    value={category.category_id}
                                    onChange={handleCategoryChange} // Correct place for onChange
                                  />
                                  <label
                                    htmlFor={`category_${category.category_id}`} // Associate label with input
                                    className="options-label"
                                  >
                                    {category.category_name}
                                  </label>
                                </div>
                                <img src={category?.image_url} />
                              </div>
                            ))}
                          </div>
                          <h3 className="variant-heading-h3"> {t("Género")}</h3>
                          <div className="gender-input">
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={productData.gender === "male"}
                                onChange={handleGenderChange}
                              />
                              {t("Masculino")}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="female"
                                checked={productData.gender === "female"}
                                onChange={handleGenderChange}
                              />
                              {t("Femenino")}
                            </label>

                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="unisex"
                                checked={productData.gender === "unisex"}
                                onChange={handleGenderChange}
                              />
                              {t("Unisexo")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Stepper-options">
                      <div className="stepper-options-selected">
                        <div
                          className={`step1-heading ${
                            stepper.step2 || "active"
                          }`}
                        >
                          <span>1</span>
                          {t("Variantes")}
                        </div>
                        <div
                          className={`connecting-line ${
                            stepper.step1 || "active"
                          }`}
                        ></div>
                        <div
                          className={`step1-heading ${
                            stepper.step1 || "active"
                          }`}
                        >
                          <span>2</span>
                          {t("Imágenes")}
                        </div>
                      </div>
                      {stepper.step1 && (
                        <div className="variant-section">
                          <AccessibleTable rows={row} handleEdit={handleEdit} />
                          <form
                            className="form-variant-submission"
                            onSubmit={handleRowAddData}
                          >
                            <label
                              style={{
                                fontFamily: "Century-BoldItalic",
                                fontSize: "1.4rem",
                                textDecoration: "underline",
                              }}
                            >
                              {t("Insertar formulario variante")}
                            </label>
                            <div className="form-with-buttons">
                              <div className="input-with-label">
                                <input
                                  type="text"
                                  required
                                  name="Size"
                                  value={dataHold?.Size}
                                  onChange={handleInputChange}
                                />
                                <label
                                  style={{ fontFamily: "Century-BoldItalic" }}
                                >
                                  {t("Tamaño")}
                                </label>
                              </div>

                              <div className="input-with-label">
                                <input
                                  type="text"
                                  required
                                  name="Color"
                                  value={dataHold?.Color}
                                  onChange={handleInputChange}
                                />
                                <label
                                  style={{ fontFamily: "Century-BoldItalic" }}
                                >
                                  {t("Color")}
                                </label>
                              </div>

                              <div className="input-with-label">
                                <input
                                  type="number"
                                  name="Quantity"
                                  required
                                  value={dataHold?.Quantity}
                                  onChange={handleInputChange}
                                />
                                <label
                                  style={{ fontFamily: "Century-BoldItalic" }}
                                >
                                  {t("Cantidad")}
                                </label>
                              </div>
                            </div>
                            <button
                              className="submit-button-form"
                              type="submit"
                            >
                              {t("Agregar variante")}
                            </button>
                          </form>
                        </div>
                      )}
                      {stepper.step2 && (
                        <div className="variant-images-section">
                          <div className="is-left-part">
                            {imageObject.map((item) => (
                              <div
                                className={`is-lp-img-name ${
                                  selectedColor === item.color
                                    ? "active selecte-img-css"
                                    : ""
                                }`}
                                key={item.id}
                                onClick={() => setSelectedColor(item.color)}
                              >
                                {item.color}
                              </div>
                            ))}
                          </div>

                          <div className="is-right-part">
                            {selectedColor && (
                              <div className="is-rp-img-name">
                                <div
                                  className="add-icons-images"
                                  onClick={handleclickinput}
                                >
                                  <FileUploadIcon />
                                </div>

                                <input
                                  type="file"
                                  ref={inputRef}
                                  multiple
                                  onChange={handleFileUpload}
                                />
                              </div>
                            )}

                            <div className="display-img-choosen">
                              {imageObject
                                .find((item) => item.color === selectedColor)
                                ?.images.map((img, index) => {
                                  const displayImg = URL.createObjectURL(img);
                                  return (
                                    <div
                                      className="img-wit-del-icon"
                                      key={index}
                                    >
                                      <img
                                        src={displayImg}
                                        alt={selectedColor}
                                      />
                                      <MdDeleteOutline
                                        onClick={() => handleImageDelete(index)}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="stepper-buttons">
                        <button
                          className=""
                          onClick={() => {
                            setStepper({ step1: true, step2: false });
                          }}
                        >
                          {t("Anterior")}
                        </button>
                        <button
                          disabled={stepper?.step2}
                          className=""
                          onClick={() => {
                            if (row?.length == 0) {
                              alert("Please add variants");
                              return;
                            }
                            setStepper({ step1: false, step2: true });
                            const uniqueColors = new Map();
                            row.forEach((item) => {
                              const colorKey = item.Color.trim().toLowerCase();
                              if (!uniqueColors.has(colorKey)) {
                                uniqueColors.set(colorKey, {
                                  color: item.Color.trim(),
                                  images: [],
                                  id: Date.now(),
                                });
                              }
                            });

                            const colorsObject = Array.from(
                              uniqueColors.values()
                            );
                            console.log("colorsObject", colorsObject);
                            setImageObject(colorsObject);
                          }}
                        >
                          {t("Próximo")}
                        </button>
                      </div>
                    </div>

                    <div className="lista-content addItem-page">
                      <div className="billboard-btn-sec">
                        {loading ? (
                          <div className="loader-progress"></div>
                        ) : (
                          <button
                            className="green-btn"
                            onClick={() => {
                              handleSubmitProduct();
                            }}
                          >
                            {t("Entregar")}
                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs lg="3"></Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addproduct;
