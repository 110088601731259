import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsPlusLg } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useRef } from "react";
import "./addproduct.css";
import { useEffect } from "react";
import { MdAdUnits, MdDelete } from "react-icons/md";
import GlobalURL, { STERLING } from "../../BaseUrl";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SketchPicker } from "react-color";
import { FaPenAlt } from "react-icons/fa";

import { useSelector } from "react-redux";
import translations from "../../../locals/translation";
import { useLocation } from "react-router-dom";

function Editproduct() {
  const location = useLocation();
  console.log("Product ID: ", location.state.product_id);
  const id = location.state.product_id;
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const navigate = useNavigate();
  const [previewImg, setPreviewImg] = useState([]);
  const [newImagePreview, setNewImagePreview] = useState([]);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState("");

  const [color, setColor] = useState("");
  const [imagesFiles, setImagesFiles] = useState([]);
  const [prosize, setProsize] = useState("");
  const [proquant, setProquant] = useState("");
  const [colorcode, setColorcode] = useState("#ffffff");
  const [head, setHead] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showPlus, setShowPlus] = useState(true);
  const [heading, setHeading] = useState("");

  const [deletingExistingImg, setDeletingExistingImg] = useState(false);

  const [colousObject, setColousObject] = useState([
    // {
    //     name: 'blue',
    //     finalImages: [1, 2, 3],
    //     previewImg: []
    // },
    // {
    //     name: 'green',
    //     finalImages: [4, 5, 6],
    //     previewImg: []
    // },
  ]);
  const [availableUnits, setAvailableUnits] = useState({
    size: [
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
    capacity: ["1", "2", "3", "4", "5", "6", "7"],
    weight: ["250", "500", "1000", "1500", "2000"],
  });
  const [selInd, setSelInd] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [mediatype] = useState("img");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sizes, setSizes] = useState([]);

  const [deleteImageIDs, setDeleteImageIDs] = useState([]);
  const [selectedVarColourID, setSelectedVarColourID] = useState("");
  const [addImages, setAddImages] = useState([]);
  const [addNewVariant, setAddNewVariant] = useState([
    {
      product_ID: "",
      size: "",
      amount: "",
      colour: "",
      images: [],
    },
  ]);
  const [variantProId, setVariantProId] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const [addingImg, setAddingImg] = useState(false);

  const [tempVariant, setTempVariant] = useState({
    size: { size: "" },
    amount: "",
    colour: { colorName: "" },
    images: [],
    newImages: [],
    existingImgID: [],
    proId: id,
  });

  const getCategory = async () => {
    try {
      const resp = await fetch(
        `${GlobalURL}/api/dev/store/getAllProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await resp.json();
      // const calcetinesCategory = data.find(category => category.category_name === "category_name");
      setCategories(data.data);
      if (resp.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
      }
    } catch (error) {
      console.log("error al obtener categoría", error);
    }
  };

  const [banner, setBanner] = useState([
    // {
    //     "size": "M",
    //     "color": "Blue",
    //     "quantity": 10,
    // },
    // {
    //     "size": "L",
    //     "color": "Red",
    //     "quantity": 5,
    // },
    // {
    //     "size": "S",
    //     "color": "Green",
    //     "quantity": 15,
    // }
  ]);

  const handleColorChange = (color) => {
    const newColor = color.hex;
    setColorcode(newColor);
  };

  const handleDeleteImageFile = (image, index) => {
    setImagesFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1); // Remove 1 element at the specified index
      return updatedFiles;
    });
  };

  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "", //quater
    baseCurrency: "USD", // qauter
    quantity: 100, // quater
    brand: "", // half
    category: "", // half
    gender: "", // quater
    seller_name: "", // half
    quantities: [
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
    ],
  });

  function handleclickinput(e) {
    inputRef.current.click();
  }

  function newImageInputFunction(e, selecColor) {
    const selectedFiles = e.target.files;

    // const newImageURLs = Array.from(selectedFiles).map((file) =>
    //   URL.createObjectURL(file)
    // );
    // setNewImagePreview((newImagePreview) => [
    //   ...newImagePreview,
    //   ...newImageURLs,
    // ]);

    setSelectedVariant((prevVariant) => ({
      ...prevVariant,
      newImages: [
        ...(prevVariant.newImages || []),
        ...Array.from(selectedFiles),
      ],
    }));

    const addImg = {
      product_color_id: selecColor?.colour?.product_color_id,
      image: selectedFiles,
    };

    setAddImages([...addImages, addImg]);
  }

  //   const handleSaveDock = () => {
  //     if (
  //       prosize === "" ||
  //       proquant === "" ||
  //       imagesFiles === "" ||
  //       color === ""
  //     ) {
  //       console.log(
  //         "Data comming ",
  //         prosize,
  //         " ",
  //         proquant,
  //         " ",
  //         imagesFiles,
  //         " ",
  //         color
  //       );
  //       Swal.fire(t("Faltan datos"));
  //       return;
  //     }

  //     // Create a new product object

  //     if (false) {
  //       Swal.fire("All Fields are required");
  //       return;
  //     }

  //     // if (!sizes.includes(prosize)) {
  //     //     setSizes((prevSizes) => [...prevSizes, prosize]);
  //     // }

  //     // Update the sizes state with the new size

  //     const selectedColorObject = colousObject.find(
  //       (item) => item.name === color
  //     );
  //     console.log("if from: ", imagesFiles);

  //     if (!selectedColorObject) {
  //       const newProduct = {
  //         size: prosize,
  //         color: color,
  //         quantity: proquant,
  //       };

  //       const AddcolourList = {
  //         name: color,
  //         finalImages: imagesFiles,
  //         previewImg: previewImg,
  //       };

  //       setColousObject((prevColourList) => [...prevColourList, AddcolourList]);
  //       setBanner((prevBanner) => [...prevBanner, newProduct]);
  //       setImagesFiles([]);
  //     } else {
  //       if (imagesFiles.length === 0) {
  //         console.log("empty image length");
  //         Swal.fire("Selected colour cannot be empty: ");
  //         return;
  //       }
  //       const newProduct2 = {
  //         size: prosize,
  //         color: color,
  //         quantity: proquant,
  //       };
  //       const AddcolourList2 = {
  //         name: color,
  //         finalImages: imagesFiles,
  //         previewImg: previewImg,
  //       };
  //       setBanner((prevBanner) => [...prevBanner, newProduct2]);
  //       setColousObject((prevState) => {
  //         // Find the index of the object with the matching name
  //         const index = prevState.findIndex(
  //           (obj) => obj.name === AddcolourList2.name
  //         );
  //         console.log("after matching index", index);
  //         if (index !== -1) {
  //           // If the object with the matching name is found, update the state
  //           const updatedColours = [...prevState];
  //           updatedColours[index] = AddcolourList2;
  //           return updatedColours;
  //         } else {
  //           // If the object with the matching name is not found, add it to the state
  //           return [...prevState, AddcolourList2];
  //         }
  //       });
  //     }

  //     // Update the banner state with the new product

  //     // Reset individual states
  //     setColor("");
  //     setImagesFiles([]);
  //     setPreviewImg([]);
  //     setProsize("");
  //     setProquant("");
  //     setColorcode("");
  //     handleClose();
  //   };

  const handleNewVariantAdd = async () => {
    console.log("tempVariant: ", tempVariant);

    if (
      tempVariant.size === "" ||
      tempVariant.amount === "" ||
      tempVariant.colour.colorName === "" ||
      tempVariant.newImages.length === 0
    ) {
      alert("All fields are required");
      return;
    }

    try {
      const formData = new FormData();

      // Append string fields to FormData
      formData.append("size", tempVariant.size);
      formData.append("amount", tempVariant.amount);
      formData.append("colour", tempVariant.colour.colorName);
      formData.append("proId", id);

      // Append File objects to FormData
      tempVariant.newImages.forEach((image, index) => {
        formData.append(`image${index + 1}`, image);
      });

      const response = await fetch(`${GlobalURL}/api/dev/store/addVariant`, {
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (!response.ok) {
        Swal.fire("Error del Servidor");
        return;
      }

      const data = await response.json();
      const jsonData = data.data;

      fetchDetails();
      setTempVariant({
        size: "",
        amount: "",
        colour: { colorName: "" },
        images: [],
        newImages: [],
        existingImgID: [],
        proId: id,
      });
      setShow(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleEditSave = () => {
    if (selectedVariant?.colour == "") {
      alert("Colour cannot be empty");
      return;
    }
    if (
      selectedVariant?.images?.length < 1 &&
      selectedVariant?.newImages?.length < 1
    ) {
      alert("Choose atleast one image");
      return;
    }
    variants.splice(selectedVariantIndex, 1, selectedVariant);
    setShow2(false);

    // deleteFromDatabseImage();
    udateProductImages();
    deleteFromDatabseImage();
  };

  const setColourHandler = (value) => {
    setColor(value);
    console.log("colour value : ", value);
    // const isOptionSelected = colousObject.some((item) => item.name === value);
    const selectedColorObject = colousObject.find(
      (item) => item.name === value
    );
    if (selectedColorObject) {
      console.log("Selected Colour object", selectedColorObject);
      const finalImageOfSCO = selectedColorObject.finalImages;
      console.log("images set durig colour set : ", finalImageOfSCO);
      setImagesFiles(finalImageOfSCO);
      // setImagesFiles(finalImageOfSCO);
      setPreviewImg(selectedColorObject.previewImg);

      // make the selected images as the images of the object
    } else {
      console.log(false);
      setPreviewImg([]);
    }
  };

  const deleteItemFromTable = (index) => {
    // Create a new array without the item at the specified index

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Está seguro?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedColourObject = [
            ...colousObject.slice(0, index),
            ...colousObject.slice(index + 1),
          ];
          const updatedBanners = [
            ...banner.slice(0, index),
            ...banner.slice(index + 1),
          ];
          setBanner(updatedBanners);
          console.log("previous colour object", colousObject);
          console.log("New colour object", updatedColourObject);
          setColousObject(updatedColourObject);

          swalWithBootstrapButtons.fire({
            title: "¡Eliminado!",
            text: "",
            icon: "success",
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelado",
            text: "",
            icon: "error",
          });
        }
      });
  };

  const handlesubmitForm = async () => {
    Swal.fire({
      title: t("Subiendo"),
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        // This will be called when the modal is closed
        // You can perform any cleanup or additional actions here
      },
      onBeforeOpen: () => {
        if (!setUploading) {
          // If uploading is not in progress, close the Swal modal
          Swal.close();
        }
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });

    // Whenever you want to start uploading, set the setUploading to true
    // For example, when initiating the upload process
    // setUploading(true);

    // When the upload is complete or canceled, set setUploading to false
    // setUploading(false);

    // let timerInterval;
    // Swal.fire({
    //     title: "Uploading",
    //     timerProgressBar: true,
    //     didOpen: () => {
    //         Swal.showLoading();
    //         const timer = Swal.getPopup().querySelector("b");
    //         timerInterval = setInterval(() => {
    //             timer.textContent = `${Swal.getTimerLeft()}`;
    //         }, 100);
    //     },
    //     willClose: () => {
    //         clearInterval(timerInterval);
    //     }
    // }).then((result) => {
    //     /* Read more about handling dismissals below */
    //     if (result.dismiss === Swal.DismissReason.timer) {
    //         console.log("I was closed by the timer");
    //     }
    // });
    console.log(banner);

    console.log("Sizes", sizes);
    setUploading(true);
    const url = `${GlobalURL}/api/dev/store/addProduct2`; // Replace with your API endpoint
    // const url = 'https://sterling-skylark-daily.ngrok-free.app/api/dev/store/addProduct2';

    function validateAndAppend(formData, key, value) {
      // Check if the value is present and not empty
      if (value !== undefined && value !== null && value !== "") {
        // Append to formData if valid
        formData.append(key, value);
        return true; // Indicate that the value was appended
      } else {
        // Log an error or handle the absence of the value
        Swal.fire(`Data Missing ${key}`);
        console.error(`Error: ${key} is missing or empty`);
        return false; // Indicate that the value was not appended
      }
    }

    const formData = new FormData();
    if (
      !validateAndAppend(formData, "name", productData.name) ||
      !validateAndAppend(formData, "seller_name", productData.seller_name) ||
      !validateAndAppend(formData, "description", productData.description) ||
      !validateAndAppend(formData, "price", productData.price) ||
      !validateAndAppend(formData, "baseCurrency", productData.baseCurrency) ||
      !validateAndAppend(formData, "brand", productData.brand) ||
      !validateAndAppend(formData, "category", selectedCategory) ||
      !validateAndAppend(formData, "gender", productData.gender)
    ) {
      return;
    }

    const uniqueSizes = [];

    banner.forEach((item, index) => {
      // Check if the size is not already in uniqueSizes
      if (!uniqueSizes.includes(item.size)) {
        // Append the unique size to uniqueSizes
        uniqueSizes.push(item.size);

        // Append the unique size to formData
        formData.append(`sizes[${index}]`, item.size);
      }
    });

    console.log(colousObject.length);
    console.log(banner.length);
    banner.forEach((item, index) => {
      // Append values for each item in the banner array
      formData.append(`quantities[${index}][size]`, item.size);
      formData.append(`quantities[${index}][color]`, item.color);
      // formData.append(`colors[${index}][name]`, item.color);
      formData.append(`quantities[${index}][quantity]`, item.quantity);
      // item.images.forEach((image, imageIndex) => {
      //     formData.append(`colors[${index}][images][${imageIndex}]`, image);
      // });
    });
    // sizes.forEach((item, index) => {
    //     formData.append(`sizes[${index}]`, item);
    // });
    colousObject.forEach((item, index) => {
      // Append values for each item in the banner array
      formData.append(`colors[${index}][name]`, item.name);
      item.finalImages.forEach((image, imageIndex) => {
        formData.append(`colors[${index}][images][${imageIndex}]`, image);
      });
    });

    const data = Object.fromEntries(formData);
    console.log(data);
    // url
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData,
      });

      if (!response.ok) {
        // Handle non-successful response here
        setUploading(false);
        Swal.fire("Error del Servidor");
        navigate("/store");
      }
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setUploading(false);
        Swal.fire(t("Producto agregado exitosamente"));
        navigate("/store");
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      setUploading(false);
      Swal.fire("Error del Servidor");
      console.error("Error:", error.message);
    }
  };

  const handleSubmitEditedForm = () => {
    udateProductDetail();
    console.log("product Details: ", productDetails);
    console.log("Variants details: ", variants);
    console.log({
      details: productDetails,
      variants: variants,
    });

    const formData = new FormData();

    // Append basic product details
    formData.append("name", productDetails.productName);
    formData.append("seller_name", productDetails.sellerName);
    formData.append("description", productDetails.description);
    formData.append("price", productDetails.price);
    formData.append("brand", productDetails.brand);
    formData.append("category", productDetails.category);
    formData.append("gender", productDetails.gender);

    const uniqueSizes = [];
    variants.forEach((item, index) => {
      if (!uniqueSizes.includes(item.size)) {
        uniqueSizes.push(item.size);
        formData.append(`sizes[${index}]`, item.size);
      }
    });
    variants.forEach((item, index) => {
      formData.append(`quantities[${index}][size]`, item.size);
      formData.append(`quantities[${index}][color]`, item.color);
      formData.append(`quantities[${index}][quantity]`, item.quantity);
    });
    variants.forEach((variant, index) => {
      formData.append(`colors[${index}][name]`, variant.colour); // Append the color name

      // Append images for the current variant
      variant.images.forEach((image, imageIndex) => {
        formData.append(`colors[${index}][images][${imageIndex}]`, image);
      });
    });

    const data = Object.fromEntries(formData);

    console.log("final edited Data: ", data);
  };

  const [productDetails, setProductDetails] = useState({
    sellerName: "",
    productName: "",
    description: "",
    price: "",
    brand: "",
    category: "",
    gender: "",
    productId: id,
  });

  const [variants, setVariants] = useState([]);

  const fetchDetails = async () => {
    Swal.showLoading();
    const token2 = localStorage.getItem("token");
    console.log("token", typeof token2);
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/store/getProductDetail`,
        {
          method: "POST", // or 'POST' or any other HTTP method
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token2}`,

            // 'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`,
          },
          body: JSON.stringify({ product_id: id }),
        }
      );

      if (!response.ok) {
        Swal.fire("Error del Servidor");
      }
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }

      const data = await response.json();
      const jsonData = data.data;
      console.log("%c Line:702 🍷 jsonData", "color:#6ec1c2", jsonData);
      setVariantProId(jsonData.product_id);

      const productColors = jsonData.product_quantities;

      const newVariants = jsonData.product_colors.map((color) => {
        // Get all images associated with the current color
        const colorImages = jsonData.product_images.filter(
          (image) => image.product_color_id === color.product_color_id
        );

        // Extract image URLs and IDs
        const imageUrls = colorImages.map((image) => image.imageUrl);
        const imageIds = colorImages.map((image) => image.product_image_id);

        // Find the quantity associated with the current color
        const quantity = jsonData.product_quantities.find(
          (quantity) => quantity.product_color_id === color.product_color_id
        );

        // Find the size associated with the current quantity
        const size = jsonData.product_sizes.find(
          (size) => size.product_size_id === quantity.product_size_id
        );

        console.log("size: ", size);

        // Return a variant object with the required data
        return {
          size: size,
          amount: quantity ? quantity.quantity : null,
          colour: color,
          images: colorImages,
          newImages: [],
          existingImgID: imageIds,
        };
      });

      console.log("newVariants: ", newVariants);

      console.log("%c Line:743 🍩 newVariants", "color:#fca650", newVariants);

      // Update the variants state with the new data
      setVariants(newVariants);
      console.log("newVariants: ", newVariants);
      Swal.close();
    } catch (error) {
      Swal.close();
      // Handle errors that occurred during the fetch
      console.error("Error fetching data:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGenderChange = (e) => {
    setProductDetails((prevState) => ({
      ...prevState,
      gender: e.target.value,
    }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setProductDetails((prevState) => ({
      ...prevState,
      category: value,
    }));
  };

  const deleteImageFromSelvariant = (index) => {
    const updatedImages = [...tempVariant.newImages];
    updatedImages.splice(index, 1);
    setTempVariant({ ...tempVariant, newImages: updatedImages });
  };

  const deleteImageFromNewImages = (itemIndex, imageIndex) => {
    setAddImages((prevImages) => {
      const updatedImages = [...prevImages];
      const images = { ...updatedImages[itemIndex].image };
      delete images[imageIndex];
      updatedImages[itemIndex] = {
        ...updatedImages[itemIndex],
        image: images,
      };
      return updatedImages;
    });

    // Remove image from previewImg state
    // setNewImagePreview((prevPreviewImg) =>
    //   prevPreviewImg.filter((_, i) => i !== index)
    // );

    // Remove image from imagesFiles state
    // setImagesFiles((prevImagesFiles) =>
    //   prevImagesFiles.filter((_, i) => i !== index)
    // );

    // Remove image from selectedVariant newImages state
    // setSelectedVariant((prevVariant) => ({
    //   ...prevVariant,
    //   newImages: prevVariant.newImages.filter((_, i) => i !== index),
    // }));
  };

  const handleImageSelectNewVariant = (e) => {
    setTempVariant({
      ...tempVariant,
      newImages: [...tempVariant.newImages, ...e.target.files],
    });
  };

  const handleNewImageSelection = (e) => {
    inputRef2.current.click();
  };

  const deleteFromDatabseImage = async () => {
    Swal.showLoading();
    setDeletingExistingImg(true);
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/store/deleteProductImage`,
        {
          method: "POST", // or 'POST' or any other HTTP method
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${localStorage.getItem("token")}`,

            // 'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`,
          },
          body: JSON.stringify({ images: deleteImageIDs }),
        }
      );

      if (!response.ok) {
        Swal.fire("Error del Servidor");
        setDeletingExistingImg(false);
      }
      if (response.code === 401) {
        setDeletingExistingImg(false);
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      setDeletingExistingImg(false);
      const data = await response.json();
      const jsonData = data.data;
      console.log("%c Line:702 🍷 jsonData", "color:#6ec1c2", jsonData);
      setVariantProId(jsonData.product_id);
      deleteImageIDs([]);
      setDeletingExistingImg(false);
      fetchDetails();
      Swal.close();
    } catch (error) {
      // Handle errors that occurred during the fetch
      console.error("Error fetching data:", error.message);
      Swal.close();
    }
  };

  const udateProductDetail = async () => {
    Swal.showLoading();
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/store/editProductGeneraldetails`,
        {
          method: "POST", // or 'POST' or any other HTTP method
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${localStorage.getItem("token")}`,

            // 'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`,
          },
          body: JSON.stringify({
            product: productDetails,
          }),
        }
      );

      if (!response.ok) {
        Swal.fire("Error del Servidor");
      }
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      Swal.close();
      const data = response.data;
      setProductDetails({
        sellerName: data.seller_name,
        productName: data.name,
        description: data.description,
        price: data.discounted_price,
        brand: data.brand,
        category: data.product_category.category_name,
        gender: data.gender,
        productId: data.product_id,
      });
    } catch (error) {
      // Handle errors that occurred during the fetch
      console.error("Error fetching data:", error.message);
      Swal.close();
    }
  };

  const udateProductImages = async () => {
    Swal.showLoading();
    setAddingImg(true);
    console.log("selectedVarColourID: ", selectedVarColourID);
    try {
      const formData = new FormData();
      formData.append("product_id", id);
      formData.append("quantity", selectedVariant.amount);
      formData.append("size_id", selectedVariant.size.product_size_id);
      formData.append("product_color_id", selectedVarColourID);

      addImages.forEach((item) => {
        console.log("item: ", item);
        console.log("item.image: ", item.image);
        if (addImages.length > 0) {
          addImages?.forEach((item) => {
            console.log("%c Line:995 🍪 item", "color:#7f2b82", item);
            Object.values(item?.image).forEach((file) => {
              console.log("file: ", file);
              formData.append("image", file); // Adjust the key as per your backend requirement
            });
          });
        }
      });

      const response = await fetch(
        `${GlobalURL}/api/dev/store/addProductColorImages`,
        {
          method: "POST",
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        setAddingImg(false);
        Swal.fire("Error del Servidor");
        return;
      }
      if (response.code === 401) {
        setAddingImg(false);
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      setAddImages([]);
      setAddingImg(false);
      fetchDetails();
      Swal.close();
    } catch (error) {
      Swal.close();
      setAddingImg(false);
      console.error("Error fetching data:", error.message);
      fetchDetails();
    }
  };

  const deleteExistingImage = (image, index) => {
    Swal.fire(t("delete?")); // cofirmation

    Swal.fire({
      title: t("¿Borrar?"),
      text: t("¡No podrás revertir esto!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("¡Sí, bórralo!"),
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteImageIDs([...deleteImageIDs, image]);
        const updatedImages = [...selectedVariant.images];
        const updatedImgIDS = [...selectedVariant.existingImgID];
        // Remove the image and the corresponding ID at the specified index
        updatedImages.splice(index, 1);
        updatedImgIDS.splice(index, 1);
        // Update the selectedVariant state with the new arrays
        setSelectedVariant((prevSelectedVariant) => ({
          ...prevSelectedVariant,
          images: updatedImages,
          existingImgID: updatedImgIDS,
        }));

        Swal.fire({
          title: t("¡Eliminado!"),
          text: t("Tu imagen ha sido eliminada."),
          icon: "success",
        });
      }
    });
  };

  const deleteExistingVariant = async (i, item) => {
    console.log("item: ", item);
    const size_id = item.size.product_size_id;
    const product_id = item.size.product_id;
    const color_id = item.colour.product_color_id;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${GlobalURL}/api/dev/store/deleteVarient`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "69420",
              },
              body: JSON.stringify({ size_id, product_id, color_id }),
            }
          );

          if (!response.ok) {
            // Handle non-successful response here
            setUploading(false);
            Swal.fire("Error del Servidor");
          }
          if (response.ok) {
            const result = await response.json();
            console.log(result);
            const updatedVariants = [...variants]; // Creating a copy of variants
            updatedVariants.splice(i, 1); // Removing the variant at index i
            setVariants(updatedVariants); // Updating the state with the modified array
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          }
        } catch (error) {
          // Handle any errors that occurred during the fetch
        }
      }
    });
  };

  const handleSizeChange = (e) => {
    const newSize = e.target.value;
    setSelectedVariant({
      ...selectedVariant,
      size: newSize,
    });
  };

  useEffect(() => {
    getCategory();
    fetchDetails();
    setProductDetails({
      sellerName: location.state.seller_name || "",
      productName: location.state.name || "",
      description: location.state.description || "",
      price: location.state.initial_price || location.state.initial_price || "",
      brand: location.state.brand || "",
      category: location.state.product_category
        ? location.state.product_category.category_name
        : "",
      gender: location.state.gender || "",
      productId: id,
    });
    console.log("heading: ", location.state.product_category);
    setSelectedCategory(location.state.product_category?.heading);
    setHeading(location.state.product_category?.heading);
  }, []);

  return (
    <div>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="editExp-page">
            <Container fluid>
              <Row>
                <Col xs lg="3"></Col>

                <Col xs lg="6">
                  <div className="exp-editPage">
                    <div className="news-header">
                      <div className="back-sec">
                        <img
                          src="./img/volver.png"
                          alt=""
                          onClick={() => {
                            navigate("/store");
                          }}
                        />
                      </div>
                      <h3
                        className="header-title"
                        style={{ fontFamily: "Francois One", fontSize: "23px" }}
                      >
                        {t("Editar producto")}
                      </h3>
                      <div className="search-icon">
                        <FiSearch />
                      </div>
                    </div>

                    <div className="common-input-forms newsAddContent">
                      <div encType="multipart/form-data">
                        <div className="common-form-control img-video-form-control">
                          <label>{t("Nombre del vendedor")}</label>
                          <div className="file-input-sec">
                            <input
                              id="uploadFile"
                              className="f-input"
                              placeholder={t("Insertar nombre del vendedor")}
                              type="text"
                              name="sellerName"
                              value={productDetails.sellerName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="common-form-control img-video-form-control">
                          <label>{t("nombre del producto")}</label>
                          <div className="file-input-sec">
                            <input
                              id="uploadFile"
                              className="f-input"
                              placeholder={t("Insertar nombre del producto")}
                              type="text"
                              name="productName"
                              value={productDetails.productName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="common-form-control img-video-form-control">
                          <label>{t("Descripción")}</label>
                          <div className="file-input-sec-text">
                            <textarea
                              id="uploadFile"
                              className="f-text"
                              placeholder={t(
                                "Insertar descripción del producto"
                              )}
                              type="text"
                              name="description"
                              value={productDetails.description}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="four-item-goup">
                          <div className="common-form-quater-control img-video-form-control">
                            <label>{t("Precio")} $</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                className="input-quater"
                                placeholder={t("Insertar precio")}
                                type="text"
                                name="price"
                                value={productDetails.price}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="common-form-quater-control img-video-form-control">
                            <label>{t("Categoría")}</label>

                            <div className="file-input-sec">
                              <select
                                className="select-tag-cat"
                                value={productDetails.category}
                                onChange={handleCategoryChange}
                                disabled={true}
                              >
                                <option value="">{t("Seleccionar")}</option>
                                {categories.map((category) => (
                                  <option
                                    key={category.category_id}
                                    id={category?.heading}
                                    value={category.category_name} // Use category_name or category_id based on your requirement
                                  >
                                    {category.category_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="common-form-half-control img-video-form-control">
                            <label>{t("Marca")}</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                className="input-half"
                                placeholder={t("Insertar nombre de marca")}
                                type="text"
                                name="brand"
                                value={productDetails.brand}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <h3 className="variant-heading-h3"> {t("Gender")}</h3>

                        <div className="gender-input">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={productDetails.gender === "male"}
                              onChange={handleGenderChange}
                            />
                            {t("Masculino")}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={productDetails.gender === "female"}
                              onChange={handleGenderChange}
                            />
                            {t("Femenino")}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="unisex"
                              checked={productDetails.gender === "unisex"}
                              onChange={handleGenderChange}
                            />
                            {t("Unisexo")}
                          </label>
                        </div>
                      </div>

                      <div className="billboard-btn-sec">
                        <button
                          className="green-btn"
                          onClick={() => {
                            handleSubmitEditedForm();
                          }}
                        >
                          {t("Entregar")}
                        </button>
                      </div>

                      <div className="submit-btn"></div>
                    </div>
                  </div>

                  <div className="lista-content addItem-page">
                    <div className="billboard-sec-inner">
                      <h3 className="variant-heading-h3"> {t("Variantes")} </h3>
                      <div className="lista-titles">
                        {/* <div className="lista-name">Size</div> */}
                        {heading === "WEIGHT" && (
                          <div className="lista-name">{t("Peso")}</div>
                        )}
                        {heading === "CAPACITY" && (
                          <div className="lista-name">{t("Capacidad")}</div>
                        )}
                        {heading !== "WEIGHT" && heading !== "CAPACITY" && (
                          <div className="lista-name">{t("Tamaño")}</div>
                        )}

                        <div className="lista-creation">
                          {heading !== "WEIGHT" ? "Color" : t("Nombre")}
                        </div>

                        <div className="lista-modification">
                          {t("Cantidad")}
                        </div>
                        <div className="lista-right">{t("Imágenes")}</div>
                        <div className="lista-modification">{t("Acción")}</div>
                      </div>
                      {variants.length < 1 ? (
                        <p>{t("No hay variante presente")}</p>
                      ) : (
                        ""
                      )}

                      {variants?.map((item, i) => {
                        console.log("item in variants: ", item);
                        if (true) {
                          return (
                            <div
                              className="myTeamBanner billboard-cards"
                              key={i}
                            >
                              <div className="lista-name ps-0">
                                <p>{item?.size?.size}</p>
                                <div className="head-desc "></div>
                              </div>
                              {
                                <div className="lista-creation">
                                  <div className="card-heading">
                                    <p>{item?.colour?.colorName}</p>
                                    {/* // colour */}
                                  </div>
                                </div>
                              }

                              <div className="lista-modification">
                                <div className="card-heading">
                                  <p>{item.amount}</p>
                                </div>
                              </div>

                              <div className="lista-right">
                                <div className="card-heading">
                                  {item.images.map((ImageItem, index) => (
                                    <div
                                      key={index}
                                      className="images-display-separate"
                                    >
                                      {/* Add a conditional check here */}
                                      <img
                                        className="image-exp"
                                        src={ImageItem?.imageUrl}
                                        alt={`Img ${index}`}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <>
                                <div className="lista-modification">
                                  {/* <FaPenAlt onClick={() => { editSection(item, i) }} /> */}
                                  <FaPenAlt
                                    onClick={() => {
                                      setSelectedVariant(item);
                                      setSelectedVarColourID(
                                        item?.colour?.product_color_id
                                      );
                                      setSelectedVariantIndex(i);
                                      setNewImagePreview([]);
                                      setShow2(true);
                                    }}
                                  />
                                  <MdDelete
                                    onClick={() => {
                                      deleteExistingVariant(i, item);
                                    }}
                                  />
                                </div>
                              </>
                            </div>
                          );
                        }
                      })}
                    </div>

                    <div className="billboard-btn-sec">
                      {/* <button className="green-btn" onClick={handleShow}>
                        {t("Agregar variante")}
                      </button> */}
                    </div>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    className="addItem-popup"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("Agregar variante")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table className="unique-table">
                        <thead>
                          <tr>
                            {heading === "WEIGHT" && (
                              <th className="size-header">{t("Peso")}</th>
                            )}
                            {heading === "CAPACITY" && (
                              <th className="size-header">{t("Capacidad")}</th>
                            )}
                            {heading !== "WEIGHT" && heading !== "CAPACITY" && (
                              <th className="size-header">{t("Tamaño")}</th>
                            )}

                            {
                              <th className="color-header">
                                {heading === "WEIGHT" ? "Name" : "Colour"}
                              </th>
                            }
                            <th className="quantity-header">{t("Cantidad")}</th>
                            <th className="images-header">{t("Imágenes")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="table-data-addproduct">
                            <td className="size-cell">
                              {heading === "WEIGHT" && (
                                <select
                                  style={{ width: "55px" }}
                                  onChange={(e) =>
                                    setTempVariant({
                                      size: {
                                        ...tempVariant,
                                        size: e.target.value,
                                      },
                                    })
                                  }
                                >
                                  <option value="">
                                    {t("Seleccionar peso")}
                                  </option>
                                  {availableUnits.weight.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {heading === "CAPACITY" && (
                                <select
                                  style={{ width: "55px" }}
                                  onChange={(e) =>
                                    setTempVariant({
                                      ...tempVariant,
                                      size: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">
                                    {t("Seleccionar capacidad")}
                                  </option>
                                  {availableUnits.capacity.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {heading !== "WEIGHT" &&
                                heading !== "CAPACITY" && (
                                  <select
                                    style={{ width: "55px" }}
                                    onChange={(e) =>
                                      setTempVariant({
                                        ...tempVariant,
                                        size: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="">
                                      {t("Selecciona el tamaño")}
                                    </option>
                                    {availableUnits.size.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}

                              {/* <input type='text' style={{ 'width': '55px' }}
                                                                value={prosize} onChange={(e) => { setProsize(e.target.value) }}
                                                            /> */}
                            </td>
                            {
                              <td className="color-cell">
                                {/* <SketchPicker color={color} onChange={handleChange} /> */}
                                {/* <SketchPicker color={colorcode} onChange={handleColorChange} /> */}
                                <div className="colr-p-sec">
                                  {/* <div className='colour-palet' style={{ 'backgroundColor': colorcode, 'width': '100%', 'height': '30px' }}></div> */}
                                  {/* <input type='text' value={color} /> */}
                                  <input
                                    list="colorOptions"
                                    id="colorInput"
                                    value={tempVariant.colour.colorName}
                                    name="color"
                                    onChange={(e) =>
                                      setTempVariant({
                                        ...tempVariant,
                                        colour: { colorName: e.target.value },
                                      })
                                    }
                                  />
                                  <datalist id="colorOptions">
                                    {colousObject.map((item, key) => (
                                      <option value={item.name} />
                                    ))}

                                    {/* Add more color options as needed */}
                                  </datalist>
                                </div>
                              </td>
                            }

                            <td className="quantity-cell">
                              <input
                                type="number"
                                style={{ width: "55px" }}
                                value={tempVariant.amount}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    return;
                                  } else {
                                    setTempVariant({
                                      ...tempVariant,
                                      amount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </td>

                            <td className="images-cell">
                              {tempVariant?.newImages?.map((image, index) => (
                                <div
                                  key={index}
                                  className="images-display-seperate"
                                >
                                  {mediatype === "img" ? (
                                    <img
                                      className="imgage-exp"
                                      src={URL.createObjectURL(image)}
                                      alt={`Img ${index}`}
                                    />
                                  ) : (
                                    <video
                                      src={URL.createObjectURL(image)}
                                      controls
                                      width="300"
                                      height="200"
                                    ></video>
                                  )}
                                  <MdDelete
                                    className="delete-colour-selected-img"
                                    onClick={() =>
                                      deleteImageFromSelvariant(index)
                                    }
                                  />
                                </div>
                              ))}
                              <input
                                type="file"
                                ref={inputRef}
                                style={{
                                  opacity: 0,
                                  width: "0px",
                                  height: "0px",
                                }}
                                multiple="multiple"
                                onChange={handleImageSelectNewVariant}
                              />

                              <div className="plus-button-add-img">
                                {showPlus && (
                                  <BsPlusLg onClick={handleclickinput} />
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose}>
                        {t("Cancelar")}
                      </Button>
                      <Button variant="primary" onClick={handleNewVariantAdd}>
                        {t("Ahorrar")}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={show2}
                    onHide={() => {
                      setShow2(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    className="addItem-popup"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("Editar variante")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table className="unique-table">
                        <thead>
                          <tr>
                            <tr>
                              {heading === "WEIGHT" && (
                                <th className="size-header">{t("Peso")}</th>
                              )}
                              {heading === "CAPACITY" && (
                                <th className="size-header">
                                  {t("Capacidad")}
                                </th>
                              )}
                              {heading !== "WEIGHT" &&
                                selectedCategory !== "CAPACITY" && (
                                  <th className="size-header">{t("Tamaño")}</th>
                                )}
                            </tr>
                            {
                              <th className="color-header">
                                {heading === "WEIGHT" ? "Name" : "Colour"}
                              </th>
                            }
                            <th className="quantity-header">{t("Cantidad")}</th>
                            <th className="images-header">{t("Imágenes")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="table-data-addproduct">
                            <td className="size-cell">
                              {selectedCategory === "WEIGHT" && (
                                <select
                                  style={{ width: "55px" }}
                                  value={selectedVariant?.size?.size}
                                  onChange={(e) => handleSizeChange(e)}
                                  disabled={true}
                                >
                                  {/* <option value={prosize}>Select Weight</option> */}
                                  {availableUnits.weight.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {selectedCategory === "CAPACITY" && (
                                <select
                                  disabled={true}
                                  style={{ width: "55px" }}
                                  value={selectedVariant?.size?.size}
                                  onChange={(e) => handleSizeChange(e)}
                                >
                                  {/* <option value={prosize}>Select Capacity</option> */}
                                  {availableUnits.capacity.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {selectedCategory !== "WEIGHT" &&
                                selectedCategory !== "CAPACITY" && (
                                  <select
                                    disabled={true}
                                    style={{ width: "55px" }}
                                    value={selectedVariant?.size?.size}
                                    onChange={(e) => handleSizeChange(e)}
                                  >
                                    {/* <option value={prosize}>Select Size</option> */}
                                    {availableUnits.size.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                            </td>
                            {
                              <td className="color-cell">
                                {
                                  <div className="colr-p-sec">
                                    <input
                                      disabled={true}
                                      type="text"
                                      value={selectedVariant.colour?.colorName}
                                      onChange={(e) => {
                                        setSelectedVariant({
                                          ...selectedVariant,
                                          colour: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                }
                              </td>
                            }

                            <td className="quantity-cell">
                              <input
                                type="number"
                                style={{ width: "55px" }}
                                value={selectedVariant.amount}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    return;
                                  } else {
                                    setSelectedVariant({
                                      ...selectedVariant,
                                      amount: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td className="images-cell">
                              <div className="existing-img">
                                <p>{t("Imágenes existentes")}</p>
                                {selectedVariant?.images?.length < 1 ? (
                                  <p>
                                    {t("No hay imagen existente disponible")}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {selectedVariant?.images?.map(
                                  (image, index) => (
                                    <div
                                      key={index}
                                      className="images-display-seperate"
                                    >
                                      {mediatype === "img" ? (
                                        <img
                                          className="imgage-exp"
                                          src={image?.imageUrl}
                                          alt={`Img ${index}`}
                                        />
                                      ) : (
                                        <video
                                          src={image?.imageUrl}
                                          controls
                                          width="300"
                                          height="200"
                                        ></video>
                                      )}
                                      <MdDelete
                                        className="delete-colour-selected-img"
                                        onClick={() =>
                                          deleteExistingImage(
                                            image,
                                            index,
                                            selectedVariant.existingImgID[index]
                                          )
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="new-images">
                                <p>{t("Nuevas imágenes")}</p>
                                {addImages?.map((item, itemIndex) =>
                                  Object.keys(item.image).map(
                                    (key, imageIndex) => (
                                      <div
                                        key={imageIndex}
                                        className="images-display-seperate"
                                      >
                                        <img
                                          className="imgage-exp"
                                          src={URL.createObjectURL(
                                            item.image[key]
                                          )}
                                          alt={`Img ${imageIndex}`}
                                        />
                                        <MdDelete
                                          className="delete-colour-selected-img"
                                          onClick={() =>
                                            deleteImageFromNewImages(
                                              itemIndex,
                                              imageIndex
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  )
                                )}
                              </div>

                              <input
                                type="file"
                                ref={inputRef2}
                                onChange={(e) => {
                                  newImageInputFunction(e, selectedVariant);
                                }}
                                style={{
                                  opacity: 0,
                                  width: "0px",
                                  height: "0px",
                                }}
                                multiple="multiple"
                              />
                              <div className="plus-button-add-img">
                                <BsPlusLg onClick={handleNewImageSelection} />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setShow2(false);
                        }}
                      >
                        {t("Cancelar")}
                      </Button>
                      <Button variant="primary" onClick={handleEditSave}>
                        {t("Guardar Editar")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>

                <Col xs lg="3"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editproduct;
