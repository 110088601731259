import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsPlusLg } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useRef } from "react";
import "./addproduct.css";
import { useEffect } from "react";
import { MdAdUnits, MdDelete } from "react-icons/md";
import GlobalURL from "../../BaseUrl";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SketchPicker } from "react-color";
import { FaPenAlt } from "react-icons/fa";

import { useSelector } from "react-redux";
import translations from "../../../locals/translation";
import { Typography } from "@mui/material";

function Addproduct() {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [previewImg, setPreviewImg] = useState([]);

  const [color, setColor] = useState("");
  const [imagesFiles, setImagesFiles] = useState([]);
  const [prosize, setProsize] = useState("");
  const [proquant, setProquant] = useState("");
  const [colorcode, setColorcode] = useState("#ffffff");
  const [head, setHead] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showPlus, setShowPlus] = useState(true);
  const [heading, setHeading] = useState("");

  const [colousObject, setColousObject] = useState([
    // {
    //     name: 'blue',
    //     finalImages: [1, 2, 3],
    //     previewImg: []
    // },
    // {
    //     name: 'green',
    //     finalImages: [4, 5, 6],
    //     previewImg: []
    // },
  ]);
  const [availableUnits, setAvailableUnits] = useState({
    size: [
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
    capacity: ["1", "2", "3", "4", "5", "6", "7"],
    weight: ["250", "500", "1000", "1500", "2000"],
  });
  const [selInd, setSelInd] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [mediatype] = useState("img");
  const [categories, setCategories] = useState([]);
  const [originalcategories, setOriginalcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sizes, setSizes] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");

  const [banner, setBanner] = useState([
    // {
    //     "size": "M",
    //     "color": "Blue",
    //     "quantity": 10,
    // },
    // {
    //     "size": "L",
    //     "color": "Red",
    //     "quantity": 5,
    // },
    // {
    //     "size": "S",
    //     "color": "Green",
    //     "quantity": 15,
    // }
  ]);
  const handleColorChange = (color) => {
    const newColor = color.hex;
    setColorcode(newColor);
  };
  const handleDeleteImageFile = (image, index) => {
    console.log("del img", image);
    console.log("del index", index);

    // Create shallow copies and remove the item at the specified index
    const newPreviewImg = [...previewImg];
    console.log("colour object: ", colousObject);
    // const newSelectedFiles = [...imagesFiles];
    // setColousObject
    newPreviewImg.splice(index, 1);
    // newSelectedFiles.splice(index, 1);

    // Update the state using destructuring
    setPreviewImg([...newPreviewImg]);
    // setImagesFiles([...newSelectedFiles]);
    console.log("prev files : ", imagesFiles);

    setImagesFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1); // Remove 1 element at the specified index
      return updatedFiles;
    });
  };
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "", //quater
    baseCurrency: "USD", // qauter
    quantity: 100, // quater
    brand: "", // half
    category: "", // half
    gender: "", // quater
    seller_name: "", // half
    quantities: [
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
      {
        colour: "",
        size: [],
        quantity: [],
      },
    ],
  });
  function handleclickinput(e) {
    inputRef.current.click();
  }
  function handleChange2(e) {
    const selectedFiles = e.target.files;
    console.log("Files ", e.target.files);
    console.log("Files inside variables", selectedFiles);
    console.log("img files: ", imagesFiles);
    console.log("lengthmkda", imagesFiles.length);

    if (imagesFiles.length > 0) {
      console.log("length executed");
      setImagesFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(e.target.files),
      ]);
    } else {
      console.log("Not execued");
      setImagesFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(e.target.files),
      ]);
    }

    // Create URLs for each selected file
    const newImages4 = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    let newImages = Array.from(selectedFiles).forEach((file) => {
      setPreviewImg((previewImg) => [...previewImg, URL.createObjectURL(file)]);
    });
    const newImages2 = Array.from(selectedFiles);
    console.log("newImages3", newImages2);
  }
  const handleSaveDock = () => {
    if (
      prosize === "" ||
      proquant === "" ||
      imagesFiles === "" ||
      color === ""
    ) {
      console.log(
        "Data comming ",
        prosize,
        " ",
        proquant,
        " ",
        imagesFiles,
        " ",
        color
      );
      Swal.fire(t("Faltan datos"));
      return;
    }

    // Create a new product object

    if (false) {
      Swal.fire("All Fields are required");
      return;
    }

    // if (!sizes.includes(prosize)) {
    //     setSizes((prevSizes) => [...prevSizes, prosize]);
    // }

    // Update the sizes state with the new size

    const selectedColorObject = colousObject.find(
      (item) => item.name === color
    );
    console.log("if from: ", imagesFiles);

    if (!selectedColorObject) {
      const newProduct = {
        size: prosize,
        color: color,
        quantity: proquant,
      };

      const AddcolourList = {
        name: color,
        finalImages: imagesFiles,
        previewImg: previewImg,
      };

      setColousObject((prevColourList) => [...prevColourList, AddcolourList]);
      setBanner((prevBanner) => [...prevBanner, newProduct]);
      setImagesFiles([]);
    } else {
      if (imagesFiles.length === 0) {
        console.log("empty image length");
        Swal.fire("Selected colour cannot be empty: ");
        return;
      }
      const newProduct2 = {
        size: prosize,
        color: color,
        quantity: proquant,
      };
      const AddcolourList2 = {
        name: color,
        finalImages: imagesFiles,
        previewImg: previewImg,
      };
      setBanner((prevBanner) => [...prevBanner, newProduct2]);
      setColousObject((prevState) => {
        // Find the index of the object with the matching name
        const index = prevState.findIndex(
          (obj) => obj.name === AddcolourList2.name
        );
        console.log("after matching index", index);
        if (index !== -1) {
          // If the object with the matching name is found, update the state
          const updatedColours = [...prevState];
          updatedColours[index] = AddcolourList2;
          return updatedColours;
        } else {
          // If the object with the matching name is not found, add it to the state
          return [...prevState, AddcolourList2];
        }
      });
    }

    // Update the banner state with the new product

    // Reset individual states
    setColor("");
    setImagesFiles([]);
    setPreviewImg([]);
    setProsize("");
    setProquant("");
    setColorcode("");
    handleClose();
  };
  const handleEditSave = () => {
    if (
      color === "" ||
      imagesFiles === "" ||
      prosize === "" ||
      proquant === ""
    ) {
      Swal.fire("cannot keep any field empty");
    }
    setBanner((prevBanner) => {
      return prevBanner.map((item, index) => {
        // Check if the index matches selInd
        if (index === selInd) {
          // Update the properties of the item with the edited values
          return {
            ...item,
            color: color,
            images: imagesFiles,
            preVimg: previewImg,
            size: prosize,
            quantity: proquant,
            Colcode: colorcode,
          };
        }
        return item; // Return the item unchanged if it's not the one to be edited
      });
    });

    // Reset states or perform other actions as needed
    setColor("");
    setImagesFiles([]);
    setPreviewImg([]);
    setProsize("");
    setProquant("");
    setShow2(false);
  };
  const setColourHandler = (value) => {
    setColor(value);
    console.log("colour value : ", value);
    // const isOptionSelected = colousObject.some((item) => item.name === value);
    const selectedColorObject = colousObject.find(
      (item) => item.name === value
    );
    if (selectedColorObject) {
      console.log("Selected Colour object", selectedColorObject);
      const finalImageOfSCO = selectedColorObject.finalImages;
      console.log("images set durig colour set : ", finalImageOfSCO);
      setImagesFiles(finalImageOfSCO);
      // setImagesFiles(finalImageOfSCO);
      setPreviewImg(selectedColorObject.previewImg);

      // make the selected images as the images of the object
    } else {
      console.log(false);
      setPreviewImg([]);
    }
  };
  const deleteItemFromTable = (index) => {
    // Create a new array without the item at the specified index

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Está seguro?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedColourObject = [
            ...colousObject.slice(0, index),
            ...colousObject.slice(index + 1),
          ];
          const updatedBanners = [
            ...banner.slice(0, index),
            ...banner.slice(index + 1),
          ];
          setBanner(updatedBanners);
          console.log("previous colour object", colousObject);
          console.log("New colour object", updatedColourObject);
          setColousObject(updatedColourObject);

          swalWithBootstrapButtons.fire({
            title: "¡Eliminado!",
            text: "",
            icon: "success",
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelado",
            text: "",
            icon: "error",
          });
        }
      });
  };
  const editSection = (item, i) => {
    console.log("product state", banner);
    console.log(item, "item index", i);

    setProquant(item.quantity);
    setProsize(item.size);

    console.log("Colour object", colousObject);

    const matchingColorObj = colousObject.find(
      (obj) => obj.name === item.color
    );

    console.log("Matching colours obj :", matchingColorObj);

    // Get the images for the color of the item

    // setSelInd(i);
    // setBanner((prevBanner) => {
    //     const updatedBanner = [...prevBanner];

    //     // const editedProduct = updatedBanner[i];

    //     // Reset individual states for the element at index i

    //     setColor(item.color);
    //     setImagesFiles([...item.images]);
    //     setPreviewImg([...item.preVimg]);
    //     setProsize(item.size);
    //     setProquant(item.quantity);
    //     setColorcode(item.Colcode);
    setShow2(true);

    //     return updatedBanner;
    // });
  };
  // const [size , setSize] = useState([]);
  const handleGenderChange = (event) => {
    const { name, value } = event.target;
    setProductData((prevProductData) => ({
      ...prevProductData,
      [name]: value,
    }));
  };
  // Normal Funtions
  const getCategory = async () => {
    try {
      const resp = await fetch(
        `${GlobalURL}/api/dev/store/getAllProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await resp.json();
      console.log("Category", data);
      // const calcetinesCategory = data.find(category => category.category_name === "category_name");
      setCategories(data.data);
      setOriginalcategories(data.data);
      if (resp.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
      }
    } catch (error) {
      console.log("error al obtener categoría", error);
    }
  };
  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value; // Get the selected category ID
    console.log("selectedCategoryId: ", selectedCategoryId);
    setSelectedCategory(selectedCategoryId);

    // Find the selected category from the categories array
    const selectedCategory = categories.find(
      (category) => category.category_id === selectedCategoryId
    );

    if (selectedCategory) {
      console.log("Selected Category: ", selectedCategory);
      setHeading(selectedCategory.heading); // Update heading
    }

    // Update setProductData with the selected category ID
    setProductData((prevData) => ({
      ...prevData,
      category: selectedCategoryId,
    }));
  };

  const handlesubmitForm = async () => {
    Swal.fire({
      title: "Uploading",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        // This will be called when the modal is closed
        // You can perform any cleanup or additional actions here
      },
      onBeforeOpen: () => {
        if (!setUploading) {
          // If uploading is not in progress, close the Swal modal
          Swal.close();
        }
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });

    // Whenever you want to start uploading, set the setUploading to true
    // For example, when initiating the upload process
    // setUploading(true);

    // When the upload is complete or canceled, set setUploading to false
    // setUploading(false);

    // let timerInterval;
    // Swal.fire({
    //     title: "Uploading",
    //     timerProgressBar: true,
    //     didOpen: () => {
    //         Swal.showLoading();
    //         const timer = Swal.getPopup().querySelector("b");
    //         timerInterval = setInterval(() => {
    //             timer.textContent = `${Swal.getTimerLeft()}`;
    //         }, 100);
    //     },
    //     willClose: () => {
    //         clearInterval(timerInterval);
    //     }
    // }).then((result) => {
    //     /* Read more about handling dismissals below */
    //     if (result.dismiss === Swal.DismissReason.timer) {
    //         console.log("I was closed by the timer");
    //     }
    // });
    console.log(banner);

    console.log("Sizes", sizes);
    setUploading(true);
    const url = `${GlobalURL}/api/dev/store/addProduct2`; // Replace with your API endpoint
    // const url = 'https://sterling-skylark-daily.ngrok-free.app/api/dev/store/addProduct2';

    function validateAndAppend(formData, key, value) {
      // Check if the value is present and not empty
      if (value !== undefined && value !== null && value !== "") {
        // Append to formData if valid
        formData.append(key, value);
        return true; // Indicate that the value was appended
      } else {
        // Log an error or handle the absence of the value
        Swal.fire(`Data Missing ${key}`);
        console.error(`Error: ${key} is missing or empty`);
        return false; // Indicate that the value was not appended
      }
    }

    const formData = new FormData();
    if (
      !validateAndAppend(formData, "name", productData.name) ||
      !validateAndAppend(formData, "seller_name", productData.seller_name) ||
      !validateAndAppend(formData, "description", productData.description) ||
      !validateAndAppend(formData, "price", productData.price) ||
      !validateAndAppend(formData, "baseCurrency", productData.baseCurrency) ||
      !validateAndAppend(formData, "brand", productData.brand) ||
      !validateAndAppend(formData, "category", selectedCategory) ||
      !validateAndAppend(formData, "gender", productData.gender)
    ) {
      return;
    }

    const uniqueSizes = [];

    banner.forEach((item, index) => {
      // Check if the size is not already in uniqueSizes
      if (!uniqueSizes.includes(item.size)) {
        // Append the unique size to uniqueSizes
        uniqueSizes.push(item.size);

        // Append the unique size to formData
        formData.append(`sizes[${index}]`, item.size);
      }
    });

    console.log(colousObject.length);
    console.log(banner.length);
    banner.forEach((item, index) => {
      // Append values for each item in the banner array
      formData.append(`quantities[${index}][size]`, item.size);
      formData.append(`quantities[${index}][color]`, item.color);
      // formData.append(`colors[${index}][name]`, item.color);
      formData.append(`quantities[${index}][quantity]`, item.quantity);
      // item.images.forEach((image, imageIndex) => {
      //     formData.append(`colors[${index}][images][${imageIndex}]`, image);
      // });
    });
    // sizes.forEach((item, index) => {
    //     formData.append(`sizes[${index}]`, item);
    // });
    colousObject.forEach((item, index) => {
      // Append values for each item in the banner array
      formData.append(`colors[${index}][name]`, item.name);
      console.log("index:o variant:  ", index);
      item.finalImages.forEach((image, imageIndex) => {
        console.log("imageIndex of Images: ", imageIndex);
        formData.append(`colors[${index}][images][${imageIndex}]`, image);
      });
    });

    const data = Object.fromEntries(formData);
    console.log(data);
    // url
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData,
      });

      if (!response.ok) {
        // Handle non-successful response here
        setUploading(false);
        Swal.fire(t("Error del Servidor"));
      }
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setUploading(false);
        Swal.fire(t("Producto agregado exitosamente"));
        navigate("/store");
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      setUploading(false);
      Swal.fire(t("Error del Servidor"));
      console.error("Error:", error.message);
    }
  };

  const handleSearchCategory = (term) => {
    if (term == "") {
      setCategories(originalcategories);
      setSearchCategory(term);
      return;
    }
    setSearchCategory(term);
    const filteredCategories = originalcategories.filter((category) =>
      category.category_name.toLowerCase().includes(term.toLowerCase())
    );
    setCategories(filteredCategories);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="editExp-page">
            <Container fluid>
              <Row>
                <Col xs lg="3"></Col>

                <Col xs lg="6">
                  <div className="exp-editPage">
                    <div className="news-header">
                      <div className="back-sec">
                        <img
                          src="./img/volver.png"
                          alt=""
                          onClick={() => {
                            navigate("/store");
                          }}
                        />
                      </div>
                      <h3
                        className="header-title"
                        style={{ fontFamily: "Francois One", fontSize: "23px" }}
                      >
                        {t("Agregar producto")}
                      </h3>
                      <div className="search-icon">
                        <FiSearch />
                      </div>
                    </div>

                    <div className="common-input-forms newsAddContent">
                      <div encType="multipart/form-data">
                        <div className="common-form-control img-video-form-control">
                          <label>{t("Nombre del vendedor")}</label>
                          <div className="file-input-sec">
                            <input
                              id="uploadFile"
                              className="f-input"
                              placeholder={t("Insertar nombre del vendedor")}
                              value={productData.seller_name}
                              onChange={(e) => {
                                const newName = e.target.value;
                                setProductData((prevData) => ({
                                  ...prevData,
                                  seller_name: newName,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <div className="common-form-control img-video-form-control">
                          <label>{t("nombre del producto")}</label>
                          <div className="file-input-sec">
                            <input
                              id="uploadFile"
                              className="f-input"
                              placeholder={t("Insertar nombre del producto")}
                              value={productData.name}
                              onChange={(e) => {
                                const newName = e.target.value;
                                setProductData((prevData) => ({
                                  ...prevData,
                                  name: newName,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <div className="common-form-control img-video-form-control">
                          <label>{t("Descripción")}</label>
                          <div className="file-input-sec-text">
                            <textarea
                              id="uploadFile"
                              className="f-text"
                              placeholder={t(
                                "Insertar descripción del producto"
                              )}
                              value={productData.description}
                              onChange={(e) => {
                                const newName = e.target.value;
                                setProductData((prevData) => ({
                                  ...prevData,
                                  description: newName,
                                }));
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="four-item-goup">
                          <div className="common-form-quater-control img-video-form-control">
                            <label>{t("Precio")} $</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                className="input-quater"
                                placeholder={t("Insertar precio")}
                                style={{ background: "transparent" }}
                                value={productData.price}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setProductData((prevData) => ({
                                    ...prevData,
                                    price: newName,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="common-form-half-control img-video-form-control">
                            <label>{t("Marca")}</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                style={{ background: "transparent" }}
                                className="input-half"
                                placeholder={t("Insertar nombre de marca")}
                                value={productData.brand}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setProductData((prevData) => ({
                                    ...prevData,
                                    brand: newName,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="common-form-quater-control img-video-form-control">
                            <label>{t("Categoría de búsqueda")}</label>
                            <div className="file-input-sec">
                              <input
                                id="uploadFile"
                                style={{ background: "transparent" }}
                                className="input-half"
                                placeholder={t("Término de búsqueda")}
                                value={searchCategory}
                                onChange={(e) => {
                                  const term = e.target.value;
                                  handleSearchCategory(term);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="file-input-sec">
                          <select
                            className="select-tag-cat"
                            style={{ background: "transparent" }}
                            value={selectedCategory}
                            disabled={banner.length}
                            onChange={handleCategoryChange}
                          >
                            <option value="">{t("Seleccionar")}</option>
                            {categories.map((category) => (
                              <option
                                key={category.category_id}
                                id={category.heading}
                                value={category.category_id}
                              >
                                {category.category_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="category-Grid">
                          {categories.map((category) => (
                            <div
                              key={category.category_id}
                              className={`options ${
                                selectedCategory == category.category_id &&
                                "opt-background"
                              }`}
                            >
                              <div className="input-label">
                                <input
                                  type="radio"
                                  id={`category_${category.category_id}`} // Unique ID
                                  name="category" // Group radio buttons
                                  value={category.category_id}
                                  onChange={handleCategoryChange} // Correct place for onChange
                                />
                                <label
                                  htmlFor={`category_${category.category_id}`} // Associate label with input
                                  className="options-label"
                                >
                                  {category.category_name}
                                </label>
                              </div>
                              <img src={category?.image_url} />
                            </div>
                          ))}
                        </div>

                        <h3 className="variant-heading-h3"> {t("Gender")}</h3>
                        <div className="gender-input">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={productData.gender === "male"}
                              onChange={handleGenderChange}
                            />
                            {t("Masculino")}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={productData.gender === "female"}
                              onChange={handleGenderChange}
                            />
                            {t("Femenino")}
                          </label>

                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="unisex"
                              checked={productData.gender === "unisex"}
                              onChange={handleGenderChange}
                            />
                            {t("Unisexo")}
                          </label>
                        </div>

                        <div className="submit-btn"></div>
                      </div>
                    </div>
                  </div>

                  <div className="lista-content addItem-page">
                    <div className="billboard-sec-inner">
                      <h3 className="variant-heading-h3"> {t("Variantes")} </h3>
                      <div className="lista-titles">
                        {/* <div className="lista-name">Size</div> */}
                        {heading === "WEIGHT" && (
                          <div className="lista-name">{t("Peso")}</div>
                        )}
                        {heading === "CAPACITY" && (
                          <div className="lista-name">{t("Capacidad")}</div>
                        )}
                        {heading !== "WEIGHT" && heading !== "CAPACITY" && (
                          <div className="lista-name">{t("Tamaño")}</div>
                        )}
                        {/* { heading !== 'WEIGHT' && (<div className="lista-creation">Colour</div>) } */}

                        <div className="lista-creation">
                          {heading !== "WEIGHT" ? "Color" : t("Nombre")}
                        </div>

                        <div className="lista-modification">
                          {t("Cantidad")}
                        </div>
                        <div className="lista-right">{t("Imágenes")}</div>
                        <div className="lista-modification">{t("Acción")}</div>
                      </div>
                      {banner?.length < 1 && (
                        <div className="my-5">
                          {t("No hay variantes disponibles")}
                        </div>
                      )}

                      {banner?.map((item, i) => {
                        if (true) {
                          return (
                            <div
                              className="myTeamBanner billboard-cards"
                              key={i}
                            >
                              <div className="lista-name ps-0">
                                <p>{item.size}</p>
                                <div className="head-desc "></div>
                              </div>
                              {
                                <div className="lista-creation">
                                  <div className="card-heading">
                                    <p>{item.color}</p>
                                  </div>
                                </div>
                              }

                              <div className="lista-modification">
                                <div className="card-heading">
                                  <p>{item.quantity}</p>
                                </div>
                              </div>

                              <div className="lista-right">
                                <div className="card-heading">
                                  {colousObject.map((imagesArr, index) => (
                                    <div
                                      key={index}
                                      className="images-display-separate"
                                    >
                                      {/* Add a conditional check here */}
                                      {imagesArr.name === item.color &&
                                        imagesArr.previewImg.map(
                                          (ImageItem, index) => (
                                            <img
                                              className="image-exp"
                                              src={ImageItem}
                                              alt={`Img ${index}`}
                                            />
                                          )
                                        )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <>
                                <div className="lista-modification">
                                  <MdDelete
                                    onClick={() => {
                                      deleteItemFromTable(i);
                                    }}
                                  />
                                </div>
                              </>
                            </div>
                          );
                        }
                      })}
                    </div>

                    <div className="billboard-btn-sec">
                      <button className="green-btn" onClick={handleShow}>
                        {t("Agregar variante")}
                      </button>
                      <button
                        className="green-btn"
                        onClick={() => {
                          handlesubmitForm();
                        }}
                      >
                        {t("Entregar")}
                      </button>
                    </div>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    className="addItem-popup"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("Agregar variante")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="unique_wrap">
                        <table className="unique-table">
                          <thead>
                            <tr>
                              {heading === "WEIGHT" && (
                                <th className="size-header">{t("Peso")}</th>
                              )}
                              {heading === "CAPACITY" && (
                                <th className="size-header">
                                  {t("Capacidad")}
                                </th>
                              )}
                              {heading !== "WEIGHT" &&
                                heading !== "CAPACITY" && (
                                  <th className="size-header">{t("Tamaño")}</th>
                                )}

                              {
                                <th className="color-header">
                                  {heading === "WEIGHT" ? "Name" : "Colour"}
                                </th>
                              }
                              <th className="quantity-header">
                                {t("Cantidad")}
                              </th>
                              <th className="images-header">{t("Imágenes")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-data-addproduct">
                              <td className="size-cell">
                                {heading === "WEIGHT" && (
                                  <select
                                    style={{ width: "55px" }}
                                    onChange={(e) => setProsize(e.target.value)}
                                  >
                                    <option value="">
                                      {t("Seleccionar peso")}
                                    </option>
                                    {availableUnits.weight.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                                {heading === "CAPACITY" && (
                                  <select
                                    style={{ width: "55px" }}
                                    onChange={(e) => setProsize(e.target.value)}
                                  >
                                    <option value="">
                                      {t("Seleccionar capacidad")}
                                    </option>
                                    {availableUnits.capacity.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                                {heading !== "WEIGHT" &&
                                  heading !== "CAPACITY" && (
                                    <select
                                      style={{ width: "55px" }}
                                      onChange={(e) =>
                                        setProsize(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {t("Selecciona el tamaño")}
                                      </option>
                                      {availableUnits.size.map(
                                        (option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}

                                {/* <input type='text' style={{ 'width': '55px' }}
                                                                value={prosize} onChange={(e) => { setProsize(e.target.value) }}
                                                            /> */}
                              </td>
                              {
                                <td className="color-cell">
                                  {/* <SketchPicker color={color} onChange={handleChange} /> */}
                                  {/* <SketchPicker color={colorcode} onChange={handleColorChange} /> */}
                                  <div className="colr-p-sec">
                                    {/* <div className='colour-palet' style={{ 'backgroundColor': colorcode, 'width': '100%', 'height': '30px' }}></div> */}
                                    {/* <input type='text' value={color} /> */}
                                    <input
                                      list="colorOptions"
                                      id="colorInput"
                                      value={color}
                                      name="color"
                                      onChange={(e) => {
                                        setColourHandler(e.target.value);
                                      }}
                                    />
                                    <datalist id="colorOptions">
                                      {colousObject.map((item, key) => (
                                        <option value={item.name} />
                                      ))}

                                      {/* Add more color options as needed */}
                                    </datalist>
                                  </div>
                                </td>
                              }

                              <td className="quantity-cell">
                                <input
                                  type="number"
                                  style={{ width: "55px" }}
                                  value={proquant}
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      return;
                                    } else {
                                      setProquant(e.target.value);
                                    }
                                  }}
                                />
                              </td>

                              <td className="images-cell">
                                {previewImg.map((image, index) => (
                                  <div
                                    key={index}
                                    className="images-display-seperate"
                                  >
                                    {mediatype === "img" ? (
                                      <img
                                        className="imgage-exp"
                                        src={image}
                                        alt={`Img ${index}`}
                                      />
                                    ) : (
                                      <video
                                        src={image}
                                        controls
                                        width="300"
                                        height="200"
                                      ></video>
                                    )}
                                    <MdDelete
                                      className="delete-colour-selected-img"
                                      onClick={() =>
                                        handleDeleteImageFile(previewImg, index)
                                      }
                                    />
                                  </div>
                                ))}
                                <input
                                  type="file"
                                  ref={inputRef}
                                  onChange={handleChange2}
                                  style={{
                                    opacity: 0,
                                    width: "0px",
                                    height: "0px",
                                  }}
                                  multiple="multiple"
                                />

                                <div className="plus-button-add-img">
                                  {showPlus && (
                                    <BsPlusLg onClick={handleclickinput} />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose}>
                        {t("Cancelar")}
                      </Button>
                      <Button variant="primary" onClick={handleSaveDock}>
                        {t("Ahorrar")}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={show2}
                    onHide={() => {
                      setShow2(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    className="addItem-popup"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("Editar variante")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="unique_wrap">
                        <table className="unique-table">
                          <thead>
                            <tr>
                              <tr>
                                {heading === "WEIGHT" && (
                                  <th className="size-header">{t("Peso")}</th>
                                )}
                                {heading === "CAPACITY" && (
                                  <th className="size-header">
                                    {t("Capacidad")}
                                  </th>
                                )}
                                {heading !== "WEIGHT" &&
                                  selectedCategory !== "CAPACITY" && (
                                    <th className="size-header">
                                      {t("Tamaño")}
                                    </th>
                                  )}
                              </tr>
                              {
                                <th className="color-header">
                                  {heading === "WEIGHT" ? "Name" : "Colour"}
                                </th>
                              }
                              <th className="quantity-header">
                                {t("Cantidad")}
                              </th>
                              <th className="images-header">{t("Imágenes")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-data-addproduct">
                              <td className="size-cell">
                                {selectedCategory === 4 && (
                                  <select
                                    style={{ width: "55px" }}
                                    onChange={(e) => setProsize(e.target.value)}
                                  >
                                    {/* <option value={prosize}>Select Weight</option> */}
                                    {availableUnits.weight.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                                {selectedCategory === 32 && (
                                  <select
                                    style={{ width: "55px" }}
                                    onChange={(e) => setProsize(e.target.value)}
                                  >
                                    {/* <option value={prosize}>Select Capacity</option> */}
                                    {availableUnits.capacity.map(
                                      (option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                                {selectedCategory !== 4 &&
                                  selectedCategory !== 32 && (
                                    <select
                                      style={{ width: "55px" }}
                                      onChange={(e) =>
                                        setProsize(e.target.value)
                                      }
                                    >
                                      {/* <option value={prosize}>Select Size</option> */}
                                      {availableUnits.size.map(
                                        (option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                              </td>
                              {
                                <td className="color-cell">
                                  {/* <SketchPicker color={color} onChange={handleChange} /> */}
                                  {/* <SketchPicker color={colorcode} onChange={handleColorChange} /> */}
                                  {/* <div className='colour-palet' style={{ 'backgroundColor': colorcode, 'width': '94%', 'height': '30px' }}></div> */}
                                  {/* <p>Color Name: <input type='text' value={color} onChange={(e) => { setColor(e.target.value) }} /></p> */}
                                  {
                                    <div className="colr-p-sec">
                                      <input
                                        type="text"
                                        value={color}
                                        onChange={(e) => {
                                          setColor(e.target.value);
                                        }}
                                      />
                                    </div>
                                  }
                                </td>
                              }

                              <td className="quantity-cell">
                                <input
                                  type="number"
                                  style={{ width: "55px" }}
                                  value={proquant}
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      return;
                                    } else {
                                      setProquant(e.target.value);
                                    }
                                  }}
                                />
                              </td>
                              <td className="images-cell">
                                {previewImg.map((image, index) => (
                                  <div
                                    key={index}
                                    className="images-display-seperate"
                                  >
                                    {mediatype === "img" ? (
                                      <img
                                        className="imgage-exp"
                                        src={image}
                                        alt={`Img ${index}`}
                                      />
                                    ) : (
                                      <video
                                        src={image}
                                        controls
                                        width="300"
                                        height="200"
                                      ></video>
                                    )}
                                    <MdDelete
                                      className="delete-colour-selected-img"
                                      onClick={() =>
                                        handleDeleteImageFile(index)
                                      }
                                    />
                                  </div>
                                ))}
                                <input
                                  type="file"
                                  ref={inputRef}
                                  onChange={handleChange2}
                                  style={{
                                    opacity: 0,
                                    width: "0px",
                                    height: "0px",
                                  }}
                                  multiple="multiple"
                                />
                                <div className="plus-button-add-img">
                                  <BsPlusLg onClick={handleclickinput} />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setShow2(false);
                        }}
                      >
                        {t("Cancelar")}
                      </Button>
                      <Button variant="primary" onClick={handleEditSave}>
                        {t("Guardar Editar")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>

                <Col xs lg="3"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addproduct;
